import { Filter, HeadCell } from "types";
import AgTableContainer from "ui-component/ag-grid/table-container";
import AgTimeLine from "ui-component/ag-timeline/Ag-Timeline";
import TimelineAccordion from "ui-component/ag-timeline/Timeline-accordion";
import { DocumentsFilterBar } from "../profile/documents";
import { useEffect, useState } from "react";
import { DocumentStatus, ExtendedDocument, ProductNote } from 'types/DocumentsTypes/Document';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
import MasterFilterBar from "ui-component/master-filter-bar";
import { Box, Collapse, Dialog, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TableContainer, Typography } from "@mui/material";
import MyRangePicker from "ui-component/MyRangePicker";
import { Filterable } from "ui-component/master-filter-bar/types";
import React from "react";
import MyDatePicker from "../../../ui-component/MyDatePicker";
import { useQueryClient } from "react-query";
import {
  CUSTOMER_DOCUMENTS, useGetCustomerAllDocuments,
  useGetCustomerAllDocumentsNoRefetch,
  useGetSpecificCustomerDocuments
} from "../../../hooks/customer/documents.hooks";
import { parseJSON } from "date-fns";
import { OrderManagment, RoutePlan } from "../../../types/plannedVactuall";
import { cancelOrderNote, docsToPlannedVsActual } from "../../../api/customer/documents.api";
import { createOrderData } from "../../../types/OrderData";
import Swal from "sweetalert2";
import RouteFilterBar from "./RoutePlanFilterBar";
import { ITableControls } from "ui-component/ag-grid/Ag-Table";
import DocumentPreview from "../profile/documents/DocumentPreview";
import DocumentsTable from '../profile/documents/Table';
import { documentTypes } from "types/DocumentsTypes/utils";

const columns: HeadCell[] = [
  {
    id: 'planName',
    numeric: false,
    label: 'מסלול מתוכנן',
    align: 'center',
    type: 'string',
    hide: false,
    valueGetter: (params) => {
      if(params.data.plannedAmount>0)
      return `(${params.data.plannedAmount}) ${params.data.planName}` ;
        else return params.data.planName;
    },

  },
  {
    id: 'cid',
    numeric: false,
    label: 'מזהה לקוח',
    align: 'center',
    type: 'string',
    hide: true
  },
  {
    id: 'city',
    numeric: false,
    label: 'עיר',
    align: 'center',
    type: 'string',
    hide: true
  },
  {
    id: 'plannedPosition',
    numeric: false,
    label: "מיקום תכנון",
    align: 'center',
    type: 'string',
    hide: true
  },
  {
    id: 'plannedDoc',
    numeric: false,
    label: 'מסמך',
    align: 'center',
    type: 'string',
    hide: true
  },
  {
    id: 'beenPosition',
    numeric: false,
    label: 'מיקום בוצע',
    align: 'center',
    type: 'string',
    hide: true
  },

  {
    id: 'value',
    numeric: true,
    label: 'כמות',
    align: 'center',
    type: 'string',
    hide: true
  },
  {
    id: 'cname',
    numeric: false,
    label: 'מסלול שבוצע',
    align: 'center',
    type: 'string',
    hide: false,
    valueGetter: (params) => {
      if(params.data.actualAmount>0)
        return `(${params.data.actualAmount}) ${params.data.cname}` ;
        else return params.data.cname;
    },

  },

  {
    id: 'date',
    numeric: false,
    label: 'תאריך ושעת ביצוע',
    align: 'center',
    type: 'string',
    hide: false,
    valueGetter: (params) => {
      const date = params.data.date;
      const time = params.data.time;
      const timeFormatted = time.split(':').map((t) => t.length === 1 ? `0${t}` : t).slice(0, 2).join(':');
      return `${date} ${timeFormatted}`;
    }
  },
  {
    id: 'docs',
    numeric: false,
    label: 'מסמכים',
    align: 'center',
    type: 'string',
    valueGetter: (params) => {
      const docs = params.data.docs;
      if(docs && docs.length > 0)
        return docs[0]?.description
      return ''
    }
  },
];

type summaryFiltersType = "noDriver" | "withDriver" | null;

const AppRoutePlan = () => {

  const [status, setStatus] = useState<DocumentStatus[]>(['open', 'approved', 'pending_approval', 'closed']);
  const [range, setRange] = useState<RangeInput<Date>>([new Date(), new Date()]);
  const [activeFilters, setActiveFilters] = React.useState<Filterable[]>([]);
  const [dataFilters, setDataFilters] = React.useState<{
    userType: string,
    orderType: string,
    docs: DocumentType[],
  }>(
    {
      userType: "pOrder",
      orderType: "order",
      docs: ['delivery_certificate','visit_note'],
    });

  const [splitByAgent, setSplitByAgent] = React.useState<boolean>(true);

  const [summaryData, setSummaryData] = React.useState<null | {
    numOfDrivers: number,
    numOfWithNoDrivers: number,
    numOfRoutes: number,
    numOfPlanned: number,
    numOfNotDone: number,
    totalValue: number,
  }>(null);
  const [summaryFilter, setSummaryFilter] = React.useState<summaryFiltersType>(null)

  const cache = useQueryClient();
  const {
    data: docs,
    isLoading,
    refetch
  } = useGetCustomerAllDocuments(range[0], range[1], false, undefined, undefined, undefined, true, true);
  const [oManagment, setManagment] = React.useState<RoutePlan[]>([]);
  React.useEffect(() => {
    if (!isLoading && range) {
      cache.removeQueries(CUSTOMER_DOCUMENTS);
      refetch();
    }
  }, [range]);
  const oCallBack = React.useCallback(
    async (ProductNote) => {
      const docs = dataFilters.docs.length ? dataFilters.docs as any : ['delivery_certificate', 'tax_invoice', 'invoice', 'refund', 'visit_note'];

      const managment = await docsToPlannedVsActual(range[0], range[1], ProductNote, docs, dataFilters.orderType, dataFilters.userType, splitByAgent);
      if (!summaryFilter) {
        setManagment(managment);
      } else {
        switch (summaryFilter) {
          case "noDriver":
            setManagment(managment.filter((m) => m.driver === "ללא driver" || !m.driver))
            break;
          case "withDriver":
            setManagment(managment.filter((m) => m.driver !== "ללא driver" && m.driver))
            break;
          default:
            setManagment(managment)
            break;
        }
      }
    }


    , [range, summaryFilter, dataFilters, splitByAgent]);
  React.useEffect(() => {
    if (docs) {
      const productDocs = docs!.filter((d) => ((d as ProductNote)?.products != undefined && (status.length === 0 || status.indexOf(d.status) > -1)) || d.type == "visit_note")
      oCallBack(productDocs)

    }
    else setManagment([]);

  }, [status, docs, summaryFilter, dataFilters, splitByAgent]);

  useEffect(() => {
    const summary = {
      numOfDrivers: 0,
      numOfWithNoDrivers: 0,
      numOfRoutes: 0,
      numOfPlanned: 0,
      numOfNotDone: 0,
      totalValue: 0
    }
    oManagment.forEach((d) => {
      if (d.driver === "ללא driver" || !d.driver) {
        summary.numOfWithNoDrivers += 1;
      } else {
        summary.numOfDrivers += 1;
      }
      summary.totalValue += d.value
      summary.numOfRoutes += d.route.filter((s) => s.cname != '').length;

      d.route.forEach((r) => {
        if (r.cname === r.planName) {
          summary.numOfPlanned += (r.actualAmount??0) - Math.max(0,(r.actualAmount??0)-(r.plannedAmount??0));
          summary.numOfNotDone+= Math.max(0,(r.actualAmount??0)-(r.plannedAmount??0));
        }
        else if (r.cname === "" || !r.cname) {
          summary.numOfNotDone += (r.plannedAmount??0);
        }
      })
    })
    if (summary.numOfRoutes !== 0 || summary.numOfPlanned !== 0 || summary.numOfNotDone !== 0 || summary.numOfDrivers !== 0 || summary.numOfWithNoDrivers !== 0) {
      setSummaryData(summary);
    }
  }, [oManagment]);

  const refSearch = React.createRef();

  const [docOptions, setDocOptions] = React.useState<any>([]);
  const [orderTypeOptions, setOrderTypeOptions] = React.useState<any>([]);

  const typeOfDocuments = Object.keys(documentTypes).map((d) => ({ label: documentTypes[d].name, value: d }));

  const filters: Filterable[] = [
    {
      id: 'userType',
      label: 'סוג משתמש',
      options: ["סדר משתמש","נהג", "מלקט", "סוכן"],
      type: 'autocomplete'
    },
    {
      id: 'orderType',
      label: 'סוג הזמנה',
      options: [...oManagment.map(d => d.route.map(r => r?.docs?.map(doc => doc.name))).flat().flat().filter((v, i, a) => a.indexOf(v) === i), "הזמנה"],
      type: 'autocomplete'
    },
    {
      id: 'docs',
      label: 'מסמכים',
      options: typeOfDocuments.map((d) => d.label),
      type: 'autocomplete'
    }
  ]

  useEffect(() => {
    setDocOptions(typeOfDocuments)
    setOrderTypeOptions([...typeOfDocuments, {
      label: "הזמנה",
      value: "order"
    }]
    )
  }, [oManagment])

  useEffect(() => {
    setActiveFilters(filters.map((f) => {
      return {
        ...f,
        values: f.id === "docs" ? ["תעודת משלוח","תעודת ביקור"] : f.id === "orderType" ? ["הזמנה"] : f.id === "userType" ? ["סדר משתמש"] : []
      }
    }
    ));
  }, []);

  const onApplyFilter = React.useCallback((filter: Filter) => {
    if (filter.property === "docs") {
      setDataFilters({
        ...dataFilters, [filter.property]: [
          ...filter?.values?.map((v) => {
            const doc = docOptions.find((d) => d.label === v);
            return doc?.value ?? v;
          }) as any ?? []
        ]
      });
      return;
    }
    if (filter.property === "userType") {
      setDataFilters({
        ...dataFilters,
        userType: (filter?.values?.[0]=="סדר משתמש" ? "pOrder": filter?.values?.[0] === "נהג" ? "driver" : filter?.values?.[0] === "מלקט" ? "collector" : "agent") || "driver"
      });
      return;
    }
    setDataFilters({
      ...dataFilters, [filter.property]: [
        ...dataFilters[filter.property],
        filter.values as any
      ]
    });
  }, [filters]);

  const onRemoveFilter = React.useCallback((filter: Filter) => {
    if (filter.property === "userType") {
      setDataFilters({
        ...dataFilters,
        userType: "driver"
      });
      return;
    };
    setDataFilters({ ...dataFilters, [filter.property]: [] });
  }, [filters]);

  const timeToNumber = (time: string) => {
    const [hours, minutes] = time.split(':');
    const total = parseInt(hours) + (parseInt(minutes) / 60);
    return total;
  }

  const numberToTime = (number: number) => {
    const hours = Math.floor(number);
    const minutes = Math.round((number - hours) * 60);
    // if hours length is 1 add 0 before
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  }

  // const collpaseOptions: {
  //   id: string,
  //   label: string,
  //   action: () => any
  // }[] = [
  //     {
  //       id: 'collapseAll',
  //       label: 'כווץ הכל',
  //       action: () => {
  //       }
  //     },
  //   ];

  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewData, setPreviewData] = React.useState<any>(null);
  const [documents, setDocuments] = React.useState<any>(null);

  const tableControls: ITableControls = {
    tableName: "reports/route-plan",
  }

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  return (
    <>
      <AgTableContainer overflow={'scroll'} controls={() => {
        return (
          <Grid container spacing={2} style={{ marginTop: -25, marginBottom: -10, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid item xs={6}>
              <MyRangePicker range={range} setRange={setRange} isLoading={isLoading} />
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">הצג לפי מנפיק</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={splitByAgent}
                  onChange={(e) => setSplitByAgent(e.target.value === "true")}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel value={false} control={<Radio />} label="לא לפי מנפיק" />
                    <FormControlLabel value={true} control={<Radio />} label="לפי מנפיק" />
                  </div>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )
      }} tableName="מסלולי נהגים">
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginTop: 10,
        }}>
          <RouteFilterBar oManagment={oManagment} summaryData={summaryData} summaryFilter={summaryFilter} setSummaryFilter={setSummaryFilter}  setDocuments={setDocuments} setPreviewData={setPreviewData} setPreviewOpen={setPreviewOpen}/>
          <MasterFilterBar onApplyFilter={onApplyFilter} onRemoveFilter={onRemoveFilter} dataFilters={dataFilters} tableName={tableControls.tableName} filters={filters} activeFilterFromAbove={activeFilters}
            ref={refSearch} />


          {oManagment.map((driver, index) => (
            <TimelineAccordion key={index} width={screenWidth < 900 ? "99%" : "49%"} metaData={{
              title: driver.driver,
              amount: driver.route.filter(r => r.cname != '').length,
              value: driver.value,
              plannedTotal: driver.route.filter(r => '' != r.planName).reduce((a, b) => a + b.plannedAmount??0, 0),
              planned: driver.route.filter(r => r.cname === r.planName).reduce((a, b) => a + (b.actualAmount??0) - Math.max(0,(b.actualAmount??0)-(b.plannedAmount??0)), 0),
              notDone: driver.route.filter(r => r.cname === "" || !r.cname).reduce((a, b) => a + b.plannedAmount??0, 0)+driver.route.filter(r => r.cname === r.planName).reduce((a, b) => a + Math.max(0,(b.plannedAmount??0)-(b.actualAmount??0)), 0),
              time: numberToTime(timeToNumber(driver.route.reverse().find((pp)=>pp.cname!="")?.time ?? "00:00") - timeToNumber(driver.route.reverse().find((pp)=>pp.cname!="")?.time ?? "00:00"))
            }} isOpen={false}
            // options={collpaseOptions}
            >
              <AgTimeLine columns={columns} data={driver.route} tableControls={tableControls} setDocuments={setDocuments} setPreviewData={setPreviewData} setPreviewOpen={setPreviewOpen} />
            </TimelineAccordion>
          ))}
        </div>
      </AgTableContainer>
      {
        previewOpen ? <Dialog fullWidth open={previewOpen} maxWidth="md" sx={{ '& .MuiDialog-paper': { p: 0 } }} onBackdropClick={() => {
          setPreviewOpen(false);
        }}>
          <DocumentPreview document={previewData} />
        </Dialog> : undefined
      }
      {
        documents && documents.length > 0
          ?
          <Dialog fullWidth
            maxWidth='lg'
            scroll='body' open={true} onBackdropClick={() => {
              setDocuments([])
            }}
            sx={{ '& .MuiDialog-paper': { p: 0 } }}>
            <Collapse in={true} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h4' gutterBottom component='div'>
                  פירוט עבור נהג
                </Typography>
                <TableContainer sx={{ maxHeight: 700 }}>
                  <DocumentsTable data={documents} />

                </TableContainer>
              </Box>
            </Collapse>
          </Dialog>
          : undefined
      }
    </>
  )
}

export default AppRoutePlan
