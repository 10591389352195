import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store';
import {getCustomers, selectCustomerById} from 'store/customers/customersSlice';
import {Box, Chip, Grid, Input, TextField, Tooltip, Typography} from '@mui/material';
import config from 'config';
import SubCard from 'ui-component/cards/SubCard';
import Logo from 'ui-component/Logo';
import MyChip from 'ui-component/extended/Chip';
import CustomerProductsPreviewList from 'views/customer/profile/products/CustomerProductsPreviewList';
import {format} from 'date-fns';
import {enUS, he} from 'date-fns/locale';
import {FormattedMessage} from 'react-intl';
import {ExtendedDocument, ProductNote} from 'types/DocumentsTypes/Document';
import IItem from 'types/product_delivery';
import {IconCurrencyShekel} from '@tabler/icons';
import {useTheme} from '@mui/styles';
import DocumentClientDetails from '../document_header_preview/DocumentClientDetails';
import {dateObjectToString, roundDecimals} from '../../../../../fromKotlin/nkutils';
import MyDatePicker from '../../../../../ui-component/MyDatePicker';
import {NoNegativeInput} from '../../../../../ui-component/textFieldNumber';
import {getSuppliers, selectSupplierById} from '../../../../../store/suppliers/suppliersSlice';
import DocumentHeaderPreview from '../document_header_preview/DocumentHeaderPreview';
import JSDB from "../../../../../fromKotlin/nk";
import {callbackNetworkResult} from "../../../../../api/customer/files.api";
import {sendReqForApi} from "../../../../../api/customer/documents.api";
import PreviewDocumentComments from '../PreviewComments';
import {deserializeDocument} from "../../../../../types/DocumentsTypes/utils";

interface DeliveryCertificatePreviewProps extends ProductNote {
    onProductChange?: (product: IItem) => void;
    onDateChange?: (date) => void;
    dater?: Date
    onIdChange?: (num) => void
    setInternalEdit?:any
    internalEdit?:any
    editting?:boolean
    pool?:string
    outerDoc?: boolean,
    collect?:boolean
}

const DeliveryCertificatePreview = ({
                                        onProductChange,
                                        onDateChange,
                                        dater,
                                        onIdChange,
                                        setInternalEdit,
                                        internalEdit,
                                        editting,
                                        outerDoc,
    pool,
                                        collect,
                                        ...document
                                    }: DeliveryCertificatePreviewProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [sendSuccess, setSendSuccess] = React.useState<boolean>(false);
    const [displayOldProducts, setDisplayOldProducts] = React.useState<boolean>(false);
    const [displayOrderProducts, setDisplayOrderProducts] = React.useState<boolean>(false);
    // TODO: Change to single customer fetching
    const customer = document.type == 'supplier_note' ? useSelector((state: RootState) => selectSupplierById(state, document.entId)) : useSelector((state: RootState) => selectCustomerById(state, document.entId));

    React.useEffect(() => {
        if (!customer && document.entId!=-1) (document.type == 'supplier_note')? dispatch(getSuppliers()) : dispatch(getCustomers());
    }, [dispatch]);
    if (!customer && document.entId!=-1) return <Box/>;
    const address =customer ? ( customer.street && customer.streetNumber ? `${customer.street}, ${customer.streetNumber}` : customer.street) :'';
    const apiConfig = JSDB().apiConfig
    const gain = roundDecimals(document.products.reduce((sum, x) => sum +  (x.costPrice ? x.costPrice : 0)  * (x.quantity  + (x.quantitySecond ?? 0) * (x.conversion_ratio ?? 0)) , 0));

    // FOR TEST - REMOVE AFTER IMPLEMENTING THE REAL OLD PRODUCTS
    // const oldProducts = [...document.products.map((x, i) => {
    //     // return every second product in the array and change the quantity to be the between 1 to 10
    //     if (i % 2 == 0) {
    //         return {
    //             ...x,
    //             quantity: Math.floor(Math.random() * 10) + 1,
    //             quantitySecond: Math.floor(Math.random() * 10) + 1,
    //         };
    //     }
    // }).filter((x) => x != undefined), {
    //     "discount": 0,
    //     "nkObject": {
    //         "productId": 5,
    //         "value": 2,
    //         "returns": 0,
    //         "price": 6,
    //         "supplier_id": -1,
    //         "use_price": 0,
    //         "use_price_tax_note": 0,
    //         "discount": 0,
    //         "wrapped_amount": 0,
    //         "wrapped_amount_return": 0,
    //         "conversion_ratio": 0,
    //         "wrapped_unit": 0,
    //         "notes": null,
    //         "doneState": 0,
    //         "cost": null,
    //         "tempPosition": 0,
    //         "isClient": true,
    //         "useWrapped": 0,
    //         "changedPrice": 1
    //     },
    //     "quantityReturns": 0,
    //     "quantitySecondReturns": 0,
    //     "totalPrice": 6,
    //     "id": 5,
    //     "external_id": "73",
    //     "name": "חלונות",
    //     "image": "",
    //     "barcode": "666",
    //     "costPrice": 4,
    //     "position": 2,
    //     "quantity": 2,
    //     "quantitySecond": 0,
    //     "no_tax_product": false,
    //     "unit": "קרטון",
    //     "in_unit": 10,
    //     "category": "קטגוריה 1",
    //     "category2": "",
    //     "available_units": [
    //         "קרטון"
    //     ],
    //     "notes": null,
    //     "conversion_ratio": 0
    // }
    // ];
    // document.order_id = "123456789"
    // document.order_products = oldProducts
    // END OF TEST TO REMOVE

    const notes2 =  JSDB().getUser().first?.getJsonDesc("notes2")?.name ?? 'הערות 2'
    const notes3 =  JSDB().getUser().first?.getJsonDesc("notes3")?.name ?? 'הערות 3'
    const notes4 =  JSDB().getUser().first?.getJsonDesc("notes4")?.name
    const notes5 =  JSDB().getUser().first?.getJsonDesc("notes5")?.name
    return (
        <SubCard
            darkTitle
            title={
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Grid container sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Typography fontWeight='bold'>
                            {document.type == 'delivery_certificate' ?
                                'תעודת משלוח ' : document.type == 'supplier_note' ? 'תעודת ספק ' : 'הזמנה '}
                            {onIdChange ?
                                <NoNegativeInput
                                    placeholder={'אוטומטי'}
                                    changer={(v) => {
                                        onIdChange(v)
                                    }}
                                /> : document.id ? document.id : ''}
                        </Typography>
                        {
                            (document?.type === 'delivery_certificate' || document?.type === "invoice") && (document?.orderId && document?.nkObject?.getConnectedOrder()) ? ( //document?.old_prodcuts?.length > 0 ? (
                                <Chip
                                    label={`הזמנה ${document?.orderId}`}
                                    color={"info"}
                                    sx={{
                                        ml: 2,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setDisplayOrderProducts(!displayOrderProducts);
                                    }}
                                />
                            ) : null
                        }
                        {
                            ( document?.type === "order") &&  document?.ref_id && document?.ref_id!='' ? ( //document?.old_prodcuts?.length > 0 ? (
                                <Chip
                                    label={document?.ref_id }
                                    color={'warning'}
                                    sx={{
                                        ml: 2,
                                        border: '1px solid #ffa875',
                                        backgroundColor: "#ffc86ccc",
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setDisplayOldProducts(!displayOldProducts);
                                    }}
                                />
                            ) : null
                        }
                        {
                            (document?.type === 'delivery_certificate' || document?.type === "order") && document?.history && document?.history?.length>0  ? ( //document?.old_prodcuts?.length > 0 ? (
                                <Tooltip title={
                                    document?.nkObject?.action_time ? `עדכון אחרון: ${document?.nkObject?.action_time}` :
                                        `עדכון אחרון: 23/09/2021`
                                }>
                                    <Chip
                                        label={"עודכן"}
                                        color={'warning'}
                                        sx={{
                                            ml: 2,
                                            border: '1px solid #ffa875',
                                            backgroundColor: "#ffc86ccc",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            setDisplayOldProducts(!displayOldProducts);
                                        }}
                                    />
                                </Tooltip>
                            ) : null
                        }
                    </Grid>
                    {(document && document.id != 0 && apiConfig && apiConfig.hasOne()) ?
                        <MyChip color={'primary'}
                              onClick={(c) => callbackNetworkResult(() => sendReqForApi(document.id, 4), (result) => {
                                  setSendSuccess(true)
                              })}
                              label={(document.one != undefined && document.one != null && document.one.send || sendSuccess) ? 'נשלח לOne' : 'לא נשלח לOne'}></MyChip> :
                        undefined
                    }
                    {(document && document.id != 0 && apiConfig && apiConfig.hasManager()) ?
                        <MyChip color={'primary'}
                              onClick={(c) => callbackNetworkResult(() => sendReqForApi(document.id, 4), (result) => {
                                  setSendSuccess(true)
                              })}
                              label={(document.manager != undefined && document.manager != null && document.manager.send || sendSuccess) ? 'נשלח למנגר' : 'לא נשלח למנגר'}></MyChip> :
                        undefined
                    }
                    {(document && document.id != 0 && apiConfig && apiConfig.hasStoreNext()) ?
                        <MyChip color={'primary'}
                              onClick={(c) => callbackNetworkResult(() => sendReqForApi(document.id, 4), (result) => {
                                  setSendSuccess(true)
                              })}
                              label={(document.storeNext != undefined && document.storeNext != null && document.storeNext.send || sendSuccess) ? 'נשלח לסטורנקס' : 'לא נשלח סטורנקטס'}></MyChip> :
                        undefined
                    }
                    {(document && document.id != 0 && apiConfig && apiConfig.hasHashavshevet()) ?
                        <MyChip color={'primary'}
                              onClick={(c) => callbackNetworkResult(() => sendReqForApi(document.id, 4), (result) => {
                                  setSendSuccess(true)
                              })}
                              label={(document.hashavshevet != undefined && document.hashavshevet != null && document.hashavshevet.send || sendSuccess) ? 'נשלח לחשבשבת' : 'לא נשלח לחשבשבת'}></MyChip> :
                        undefined
                    }
                </div>

            }
            secondary={<>

                <Logo width={50}/>
            </>
            }
        >
            <Grid container spacing={2}>
                <DocumentHeaderPreview/>
                <Grid item xs={12}>
                    <Grid container>
                        <DocumentClientDetails address={address} name={customer?.name}
                                               businessName={customer?.businessName} businessId={customer?.businessId}
                                               phone={customer?.phone} email={customer?.email ?? ''} id={customer?.id}
                                               external_id={customer?.externalId ?? ''}/>
                        <Grid item sm={6}>
                            <Grid container>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    {document.type == 'delivery_certificate' ?
                                        'תעודת משלוח ' : 'פרטי הזמנה '}{document.id ? document.id : ''}
                                </Typography>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                תאריך מסמך:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {onDateChange ? <MyDatePicker date={dater ?? document.date} autoFocus={true}
                                                                          setDate={onDateChange}
                                                                          isLoading={undefined}/> :
                                                <Typography variant='body2'>
                                                    {format(document.date, 'PPP', {
                                                        locale: config.i18n === 'he' ? he : enUS
                                                    })}
                                                </Typography>}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                תאריך הנפקה:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='body2'>
                                                {format(document.issueDate, 'PPPPp', {
                                                    locale: config.i18n === 'he' ? he : enUS
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                <FormattedMessage id='Status'/>:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>

                                            <MyChip
                                                label={document?.orderStatus ?? <FormattedMessage id={document.status}/>}
                                                variant='outlined'
                                                size='small'
                                                chipcolor={
                                                    document.status === 'pending_approval'
                                                        ? 'warning'
                                                        : document.status === 'approved'
                                                            ? 'success'
                                                            : document.status === 'open'
                                                                ? 'error'
                                                                : ''
                                                }
                                            />
                                        </Grid>
                                        {(document.bul && document.bul !== -1) ?
                                            <>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        מספר בול:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant='body2'>
                                                        {document.bul}
                                                    </Typography>
                                                </Grid>
                                            </> : undefined
                                        }
                                        {
                                            !onIdChange && onProductChange ? (
                                                <>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body2'>
                                                            מספר בול:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Input
                                                        type='number'
                                                        value={internalEdit?.bul ??  document.bul}
                                                        onChange={(e) => setInternalEdit && setInternalEdit({...internalEdit,bul:Number(e.target.value)})}
                                                        />
                                                    </Grid>
                                                </>
                                            ) :
                                                null
                                        }

                                        <>
                                            <Grid item xs={4}>
                                                <Typography variant='body2'>
                                                    סוכן:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant='body2'>
                                                    {document.agent}
                                                </Typography>
                                            </Grid>
                                        </>

                                        <>
                                        {(editting ||  outerDoc )  &&
                                            <>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        { notes2}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Input
                                                        value={internalEdit?.notes2 ?? document?.notes2}
                                                        onChange={(e) => outerDoc ? outerDoc(prev=>{
                                                            return {...prev,notes2:e.target.value}
                                                        }): setInternalEdit && setInternalEdit({...internalEdit,notes2:e.target.value})}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        {document?.notes2 && document?.notes2.length > 0 && (!(editting ||  outerDoc )) &&
                                            <>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        { notes2}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant='body2'>
                                                        {
                                                            document?.notes2
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        </>
                                        <>
                                        {(editting ||  outerDoc ) &&
                                            <>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        { notes3}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Input
                                                        value={internalEdit?.notes3 ?? document?.notes3}
                                                        onChange={(e) => outerDoc ? outerDoc(prev=>{
                                                            return {...prev,notes3:e.target.value}
                                                        }): setInternalEdit && setInternalEdit({...internalEdit,notes3:e.target.value})}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        {document?.notes3 && document?.notes3.length && !(editting ||  outerDoc ) &&
                                            <>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        { notes3}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant='body2'>
                                                        {
                                                            document?.notes3
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        </>
                                        <>
                                            {(editting ||  outerDoc ) &&
                                                <>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body2'>
                                                            { notes4}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Input
                                                            value={internalEdit?.notes4 ?? document?.notes4}
                                                            onChange={(e) => outerDoc ? outerDoc(prev=>{
                                                                return {...prev,notes4:e.target.value}
                                                            }): setInternalEdit && setInternalEdit({...internalEdit,notes4:e.target.value})}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            {document?.notes4 && document?.notes4.length && !(editting ||  outerDoc ) &&
                                                <>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body2'>
                                                            { notes4}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography variant='body2'>
                                                            {
                                                                document?.notes4
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                        </>
                                        <>
                                            {(editting ||  outerDoc ) &&
                                                <>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body2'>
                                                            { notes5}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Input
                                                            value={internalEdit?.notes5 ?? document?.notes5}
                                                            onChange={(e) => outerDoc ? outerDoc(prev=>{
                                                                return {...prev,notes5:e.target.value}
                                                            }): setInternalEdit && setInternalEdit({...internalEdit,notes5:e.target.value})}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            {document?.notes5 && document?.notes5.length && !(editting ||  outerDoc ) &&
                                                <>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body2'>
                                                            { notes5}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography variant='body2'>
                                                            {
                                                                document?.notes5
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                        </>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{mt: 2}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomerProductsPreviewList products={document.products.sort((a, b) => (
                                        (a?.notePlacement != undefined || b?.notePlacement != undefined) ? ((a?.notePlacement ?? 0) - (b?.notePlacement ?? 0)) :
                                            (a?.position ?? 2000 - b?.position ?? 2000))
                                    )}
                                                                 withReturns={document.type == 'delivery_certificate' || document.type == 'supplier_note'|| JSDB().curUser?.returns==2}
                                                                 disableHoverableIncrementer={document.status === 'approved'}
                                                                 onProductChange={onProductChange} date={document.date}
                                                                 id={customer?.id}
                                                                 displayCost={document.type == 'delivery_certificate'}
                                                                 sup={document.type == 'supplier_note'}
                                                                 // oldProducts={displayOldProducts ? document?.history : []}
                                                                 oldProducts={displayOldProducts ? document?.history?.filter((p)=>{
                                                                     const x = document?.type === "delivery_certificate" && (document?.orderId && document?.nkObject?.getConnectedOrder()?.getKartonString())  ?  document?.nkObject?.getConnectedOrder():undefined
                                                                     const y =  document?.type === "order" && ( document?.nkObject?.getKartonString()) ? document?.nkObject : undefined
                                                                     if(x) {

                                                                         return x.isCollected(p.id)
                                                                     } else if (y){
                                                                         return y.isCollected(p.id)
                                                                     } else{
                                                                         return true
                                                                     }

                                                                 }) : []}
                                                                 displayOldProducts={displayOldProducts}
                                                                 orderProducts={displayOrderProducts && !editting ?  deserializeDocument(document?.nkObject?.getConnectedOrder())?.products ??[] :undefined}
                                                                 displayOrderProducts={!editting && displayOrderProducts}
                                                                 collect={collect}
                                                                 jumpToWrapped={document.type == 'order'}
                                                                 />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SubCard
                        sx={{

                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                        }}
                    >
                        <Grid container rowSpacing={0}>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    סהכ לפני מעמ
                                </Typography>
                            </Grid>
                            <Grid item style={{direction: 'ltr'}} xs={5}>
                                <IconCurrencyShekel size={10} color='black'/>
                                {roundDecimals(document.mainValue - ((document.taxValue) ? document.taxValue : 0)).toLocaleString()}

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    מעמ
                                </Typography>
                            </Grid>
                            <Grid item style={{direction: 'ltr'}} xs={5}>
                                <IconCurrencyShekel size={10} color='black'/>
                                {roundDecimals(((document.taxValue) ? document.taxValue : 0)).toLocaleString()}

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    <FormattedMessage
                                        id='Total'/>
                                </Typography>
                            </Grid>
                            <Grid item style={{direction: 'ltr'}} xs={5}>
                                <IconCurrencyShekel size={10} color='black'/>
                                {roundDecimals(document.mainValue).toLocaleString()}

                            </Grid>
                            {gain>0 && document.type == 'delivery_certificate' ?<> <Grid item xs={6}>
                                    <Typography variant='subtitle1' fontWeight='bold'>
                                        (רווח לפני מעמ)
                                    </Typography>
                                </Grid>
                                <Grid item style={{direction: 'ltr'}} xs={5}>
                                    (<IconCurrencyShekel size={10} color='black'/>
                                    {roundDecimals(roundDecimals(document.mainValue - ((document.taxValue) ? document.taxValue : 0))-gain).toLocaleString()})

                                </Grid></>: undefined}


                        </Grid>
                        {document?.type === "delivery_certificate" && (document?.orderId && document?.nkObject?.getConnectedOrder()?.getKartonString()) ?
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                    ליקוט: {     document?.nkObject?.getConnectedOrder()?.getKartonString()}
                                </Typography>

                            </Grid>  : undefined
                        }
                        {document?.type === "order" && ( document?.nkObject?.getKartonString()) ?
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                    ליקוט: {     document?.nkObject?.getKartonString()}
                                </Typography>

                            </Grid>  : undefined
                        }
                         <Grid item xs={12}>
                            <Grid item xs={12}>
                                <PreviewDocumentComments outerDoc={outerDoc} comment={document.comment}  doc={ document} />
                            </Grid>
                        </Grid>

                    </SubCard>
                </Grid>
            </Grid>
        </SubCard>
    );
};

export default DeliveryCertificatePreview;
