import JSDB, {ApiCall, NKTypeConvertor} from '../../fromKotlin/nk';
import {base64ToArrayBuffer, dateObjectToString, printPdf, saveByteArray} from '../../fromKotlin/nkutils';
import {DocumentType} from '../../types/DocumentsTypes/Document';
import {documentTypes} from '../../types/DocumentsTypes/utils';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {sendPdfEmail} from './documents.api';

export const dataToAccountent: (fromDate: Date, toDate: Date) => Promise<any> = async (fromDate, toDate) => {
    const req: ApiCall = new ApiCall('DATA_TO_ACCOUNT_MANAGER');
    req.putParam('date_range', `${dateObjectToString(fromDate)}..${dateObjectToString(toDate)}`);
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const dataToHash: (fromDate: Date, toDate: Date,ids?: number[],doc_types?:number[]) => Promise<any> = async (fromDate, toDate,ids,doc_types) => {
    const req: ApiCall = new ApiCall('CALCULATE_API_HASHAVSHEVET_zip');
    req.putParam('date_start', dateObjectToString(fromDate));
    req.putParam('date_end', dateObjectToString(toDate));
    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    req.putParam('types', NKTypeConvertor().toKotlinList(doc_types));

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const edi100: (id) => Promise<any> = async (id) => {
    const req: ApiCall = new ApiCall('EDI_100_CALC');
    req.putParam('tax_id',Number(id));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const getReportPdf: (fromDate: Date, toDate: Date, reportType) => Promise<any> = async (fromDate, toDate, reportType) => {
    const req: ApiCall = new ApiCall('CALCULATE_CLIENT_REPORT_PDF');

    req.putParam('fromDate', dateObjectToString(fromDate));
    req.putParam('toDate', dateObjectToString(toDate));
    req.putParam('report_type', reportType);
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};
export const sendMsgAll: (ids: number[], msg: string, title: string, doc_types?: number[], emails?: string[], phones?: string[], action_date?: Date,special_arg?:string,external_var?:string[]) => Promise<any> = async (ids, msg, title, doc_types, emails, phones, action_date,special_arg,external_var) => {
    const req: ApiCall = new ApiCall('SEND_MSG_ALL');

    if (action_date)
        req.putParam('action_date', dateObjectToString(action_date));
    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    req.putParam('msg', msg);

    req.putParam('title', title);
    if (doc_types)
        req.putParam('doc_type', NKTypeConvertor().toKotlinList(doc_types));
    if (emails)
        req.putParam('emails', NKTypeConvertor().toKotlinList(emails));
    if (phones)
        req.putParam('phones', NKTypeConvertor().toKotlinList(phones));
    if (special_arg)
        req.putParam('special_arg', special_arg);
    if (external_var)
        req.putParam('external_var', NKTypeConvertor().toKotlinList(external_var));
    req.putParam('agent', JSDB().currentAgent);
    const result = await req.execute();

    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result;

};
export const dataMimshak: (fromDate: Date, toDate: Date, method?: string, c_ids?: number[], ids?: number[]) => Promise<any> = async (fromDate, toDate, method, c_ids, ids) => {
    const req: ApiCall = new ApiCall('CALCULATE_API_EXCEL_zip');
    if (method)
        req.putParam('xlsxType', method);
    req.putParam('date_start', dateObjectToString(fromDate));
    req.putParam('date_end', dateObjectToString(toDate));
    if (c_ids)
        req.putParam('c_ids', NKTypeConvertor().toKotlinList(c_ids));
    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const pricesPdf: (id: number, fromDate: Date, p_ids?: number[]) => Promise<any> = async (id, fromDate, p_ids) => {
    const req: ApiCall = new ApiCall('GET_CLIENT_PRICES_PDF');

    req.putParam('id', id);
    req.putParam('date', dateObjectToString(fromDate));
    if (p_ids)
        req.putParam('p_ids', NKTypeConvertor().toKotlinList(p_ids));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const uploadLogo: (logo: string) => Promise<any> = async (logo) => {
    const req: ApiCall = new ApiCall('UPLOAD_LOGO');
    const img = base64ToArrayBuffer(logo);
    req.putParam('image', img);

    const result = await req.execute();

    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return true;
};


export const docsToXSLX: (fromDate: Date, toDate: Date, dtype?: DocumentType, pdf?: boolean, specificEntId?: number) => Promise<any> = async (fromDate, toDate, dtype, pdf, specificEntId) => {
    const req: ApiCall = new ApiCall('CALCULATE_DOCUMENTS_SUM');
    req.putParam('date_range', `${dateObjectToString(fromDate)}..${dateObjectToString(toDate)}`);
    if (dtype)
        req.putParam('doc_type', documentTypes[dtype].nkType);
    if (pdf) {
        req.putParam('pdf', pdf);
    }


    if (specificEntId) {
        req.putParam('ent_id', specificEntId);
    }

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const apiXSLX: (fromDate: Date, toDate: Date) => Promise<any> = async (fromDate, toDate) => {
    const req: ApiCall = new ApiCall('CALCULATE_API_EXCEL_zip');
    req.putParam('date_start', fromDate);
    req.putParam('date_end', toDate);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const documentsZip: (fromDate: Date, toDate: Date, dtype: DocumentType) => Promise<any> = async (fromDate, toDate, dtype) => {
    const req: ApiCall = new ApiCall('CALCULATE_document_zip');


    req.putParam('doc_type', documentTypes[dtype].nkType);
    req.putParam('date_range', `${dateObjectToString(fromDate)}..${dateObjectToString(toDate)}`);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const clientsXlsx: (ids: number[]) => Promise<any> = async (ids) => {
    const req: ApiCall = new ApiCall('CLIENTS_XLSX');

    req.putParam('clients', NKTypeConvertor().toKotlinList(ids));

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};
export const productsXlsx: (ids: number[]) => Promise<any> = async (ids) => {
    const req: ApiCall = new ApiCall('PRODUCTS_XLSX');

    req.putParam('products', NKTypeConvertor().toKotlinList(ids));

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};
export const clientsDebtsXLSX: (date: Date, agentId?: number) => Promise<any> = async (date, agentId) => {
    const req: ApiCall = new ApiCall('CALCULATE_client_debts');

    if (agentId)
        req.putParam('agent', agentId);


    req.putParam('date_range', dateObjectToString(date));

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return (result.first)!;
};

export const fetchMimshakClient: (date?: Date, ) => Promise<any> = async (date) => {
    const req: ApiCall = new ApiCall('READ_MIMSHAK');

    if(date)
    req.putParam('to_date', dateObjectToString(date));

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return true;
};


export const getFile = async (print: boolean, func: () => Promise<any>, title, type?: string, callback?: () => void, setLoader?: (d) => void, email?: (b, t) => Promise<boolean>) => {
    try {
        if (setLoader) {
            setLoader(true);
        }
        const byteArray = await func();
        if (email) {
            await email(byteArray, title)
        } else {
            if (print)
                printPdf(byteArray);
            else
                saveByteArray(byteArray, title, type);

        }

        if (callback)
            callback()

        if (setLoader) {
            setLoader(false);
        }
    } catch (e) {
        Swal.fire({
            title: 'שגיאה בגישה לנתונים',
            target: '#custom-target',
            text: '',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#d63030'
        });
        setLoader?.call(false)

    }


};

export function readFile(file) {
    return new Promise((resolve, reject) => {
        // Create file reader
        let reader = new FileReader()

        // Register event listeners
        reader.addEventListener("loadend", e => resolve(e.target.result))
        reader.addEventListener("error", reject)

        // Read file
        reader.readAsArrayBuffer(file)
    })
}

export async function getAsByteArray(file) {
    return new Int8Array(await readFile(file))
}

export const load_from_xlsx: (f: any, load_type: string, type?: string, date?: Date, load_doc_id?: boolean) => Promise<any> = async (f, load_type, type, date, load_doc_id) => {
    const req: ApiCall = new ApiCall('LOAD_XLSX');

    req.putParam('f', f);
    req.putParam('load_type', load_type);
    if (type)
        req.putParam('type', type);
    if (date)
        req.putParam('date', dateObjectToString(date));
    if (load_doc_id)
        req.putParam('load_doc_id', load_doc_id);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // str result
    return (result.first)!;
};

export const whatsappRefresh: () => Promise<boolean> = async () => {
    const req: ApiCall = new ApiCall('RESET_WHATSAPP_API');


    const result = await req.execute();

    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return true;
};
export const callbackNetworkResult = async (func: () => Promise<any>, callback: (result) => void,loader?:any) => {
    try {
        if(loader)
            loader(true);
        const str = await func();
        callback(str);
        if(loader)
            loader(false);
    } catch (e) {
        Swal.fire({
            title: 'שגיאה בגישה לנתונים',
            target: '#custom-target',
            text: '',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#d63030'
        });
        if(loader)
            loader(false);

    }


};

