import { KeyedObject, GetComparator, Filter } from 'types';
import { isBefore, isAfter } from 'date-fns';

function descendingComparator(a: KeyedObject, b: KeyedObject, orderBy: string, extractFunc?: any) {
    if (extractFunc ? !extractFunc(a) : !a[orderBy]) {
        return 1;
    }
    if (extractFunc ? !extractFunc(b) : !b[orderBy]) {
        return -1;
    }
    if (extractFunc ? extractFunc(b) < extractFunc(a) : b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (extractFunc ? extractFunc(b) > extractFunc(a) : b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const getComparator: GetComparator = (order, orderBy, extractFunc?: any) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy, extractFunc) : (a, b) => -descendingComparator(a, b, orderBy, extractFunc);

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
    const stabilizedThis = array.map((el: any, index: number) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return (a[1] as number) - (b[1] as number);
    });
    return stabilizedThis.map((el) => el[0]);
}
interface ObjectWithField {
    [key: string]: any; // This interface allows any key to be used to access the object properties
}
export function distinctByFieldWithCount<T extends Record<string, any>>(
    arr: T[],
    fieldName: keyof T | ((item: T) => any)
): (T & { count: number })[] {
    // First pass: count occurrences of each distinct field value
    const counts = new Map<any, number>();
    for (const obj of arr) {
        const key = typeof fieldName === "function" ? fieldName(obj) : obj[fieldName];
        counts.set(key, (counts.get(key) || 0) + 1);
    }

    // Second pass: build result array, preserving order
    const result: (T & { count: number })[] = [];
    const seen = new Set<any>();

    for (const obj of arr) {
        const key = typeof fieldName === "function" ? fieldName(obj) : obj[fieldName];
        // Only push the first time we see this key
        if (!seen.has(key)) {
            seen.add(key);
            result.push({
                ...obj,
                count: counts.get(key) ?? 1
            });
        }
    }

    return result;
}

export function distinctByField(arr: ObjectWithField[], fieldName: any): ObjectWithField[] {
    const distinctValues = new Set();
    return arr.filter((obj) => {
        if (!distinctValues.has(typeof fieldName=="function" ? fieldName(obj):obj[fieldName])) {
            distinctValues.add(typeof fieldName=="function" ? fieldName(obj):obj[fieldName]);
            return true;
        }
        return false;
    });
}
export const distinct = (data: any[]) =>
    data.reduce((acc, c) => {
        Object.keys(c).forEach((k) => {
            if (c[k]) {
                if (k in acc) {
                    if (!acc[k].includes(c[k])) {
                        acc[k] = acc[k].concat(c[k]);
                    }
                } else {
                    acc[k] = [c[k]];
                }
            }
        });
        return acc;
    }, {});
export const selectiveDistinct = (data: any[], columns) => {
    const res = {};

    columns.forEach((c) => {
        res[c.id] = new Set();
        if(c.extendedSearchProperties) {
            c.extendedSearchProperties.forEach((es)=>{
                if(!res[es]){
                    res[es]=new Set()
                }
                data.forEach((d) => {
                    const v = c.extractFunc ? c.extractFunc(d) : d[es];
                    if (v != undefined) {
                        res[es].add(v.toString().trim());
                    }
                });

            })
        }
        data.forEach((d) => {
            const v = c.extractFunc ? c.extractFunc(d) : d[c.id];
            if (v != undefined) {
                res[c.id].add(v.toString().trim());
            }
            if(c.searchFunc ){
                const v= c.searchFunc(d)
                v?.forEach((cv)=>{
                    if(cv)
                        res[c.id].add(cv.toString().trim());
                })
            }
        });

    });
    Object.keys(res).forEach((k)=>{
        res[k] = Array.from(res[k]);
    })
    return res;
};
export const applyFilters = (data: any[], filters: Filter[],date?:Date) => data.filter((c) => filters.length === 0 ||
    filters.every(({ property, queryFunc, others, contains, eq, from, to, values, custom }) => {
        let matches = true;
        const properties = [...(others ?? []), property];
        let containsQuery = false;

        properties.forEach((p) => {
            const curValue = c[p];
            if (custom && custom(c,date)) {
                containsQuery = true;
            }
            if (from && to) {
                if (isAfter(curValue, from) && isBefore(curValue, to)) {
                    containsQuery = true;
                }
            } else if (values && values?.length > 0) {

                if (values.some((v) => curValue == v || (v?.toString() && curValue?.toString() && v.toString().trim()==curValue.toString().trim()))) {
                    containsQuery = true;
                }
                if (contains && curValue?.toString()?.includes(contains)) {
                    containsQuery = true;
                }
            }
            // eslint-disable-next-line eqeqeq
            else if (eq ? curValue?.toString() == contains : curValue?.toString()?.includes(contains) ?? false) {
                containsQuery = true;
            }
        });

        if (queryFunc) {
            const curValues = queryFunc(c);
            if (curValues.length > 0) {
                if (values && values?.length > 0) {
                    if (values.some((v) => curValues.some((curValue) => curValue.toString() === v))) {
                        containsQuery = true;
                    }
                }
                // eslint-disable-next-line eqeqeq
                else if (eq ? curValues.some((curValue) => curValue.toString() === contains) : curValues.some((curValue) => curValue.toString().includes(contains))) {
                    containsQuery = true;
                }
            } else {
                containsQuery = false;
            }
        }
        if (!containsQuery) {
            matches = false;
        }
        return matches;
    })
);

export function applySelection<D>(data: D[], item: D, keyExtractor) {
    const selectedIndex = data.findIndex((s) => keyExtractor(s) === keyExtractor(item));
    let newSelected: D[] = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(data, item);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(data.slice(1));
    } else if (selectedIndex === data.length - 1) {
        newSelected = newSelected.concat(data.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(data.slice(0, selectedIndex), data.slice(selectedIndex + 1));
    }

    return newSelected;
}
export function subStringMatch(str: string, options: string[]): boolean {
    const splitSearch = str.split(" ");
    const splitFull = options.filter((s)=> s).map((s) => s.toString().trim())

    for (let s of splitSearch) {
        if (!splitFull.some(part => part.includes(s))) {
            return false;
        }
    }
    return true;
}

export function arraysEqual(a, b): boolean {
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}
export default stableSort;
