import React, {memo, useCallback, useMemo} from 'react';

// Material UI
import {
    Autocomplete,
    Box,
    Chip,
    IconButton,
    Input,
    InputAdornment, MenuItem,
    TableCell, TextField,
    Tooltip,
    Typography
} from '@mui/material';



// Types
import { HeadCell } from 'types';
import IItem from 'types/product_delivery';

import { roundDecimals } from '../../../../fromKotlin/nkutils';
import AgTable, { ITableControls } from 'ui-component/ag-grid/Ag-Table';
import {AllInclusive, Person} from '@mui/icons-material';
import basicPinnedControl from "../../../../ui-component/ag-grid/utils/basicPinnedTopRow";

interface ProductItemProps {
    row: IItem;
    onProductChange: (product: IItem) => void;
    withReturns: boolean;
}

const columns: HeadCell[] = [
    // {
    //     id: '',
    //     numeric: false,
    //     label: '',
    //     align: 'center',
    //     filterable: false
    // },
    {id: 'id', numeric: false, label: 'מזהה', align: 'center', type: 'string', sortable: true, hide: true},
    {
        id: 'external_id', numeric: false, label: 'מזהה חיצוני', align: 'center', type:'numOrStr', sortable: true, hide: true,

    },
    {
        id: 'priority', numeric: false, label: 'עדיפות', align: 'center', type: 'number', sortable: true, hide: true,

    },
    {
        id: 'name',
        numeric: false,
        label: 'שם מוצר',
        align: 'center',
        type: 'string',
        pinned: "right",
        extendedSearchProperties: ['name', 'barcode'],
    },
    {
        id: 'barcode',
        numeric: false,
        label: 'ברקוד',
        align: 'center',
        hide: true,
        type: 'string',
        extendedSearchProperties: ['name', 'barcode'],
    },
    { id: 'category', numeric: false, label: 'קטגוריה', align: 'center', type: 'string', sortable: true, hide: true, },
    { id: 'group', numeric: false, label: 'קבוצה', align: 'center', type: 'string', sortable: true, hide: true, },
    {
        id: 'quantitySecond',
        numeric: true,
        label: 'מידה',
        hide: true,
        align: 'center',
        type: 'number',
        filterable: false,
        sortable: true,
        dataFormat: {
            str: "קרטון"
        },
        editable: (params) => params?.data?.available_units && params?.data?.available_units?.length == 2,
        cellRenderer({ params, row }) {
            return (
                <>
                    {params?.data?.available_units && params?.data?.available_units?.length == 2 ? <Typography variant="h5"> {params?.data?.quantitySecond}</Typography> : undefined}
                    <Typography style={{
                        direction: "rtl",
                    }} >
                        {params?.data?.available_units && params?.data?.available_units?.length == 2 ? (
                            params?.data?.conversion_ratio < 1 && params?.data?.conversion_ratio > 0 ? ` ${1 / params?.data?.conversion_ratio} ${params?.data?.available_units?.[1]} =  ${params?.data?.available_units?.[0]} ` :
                            params?.data?.conversion_ratio >= 1 ? `${params?.data?.available_units?.[1]} = ${params?.data?.conversion_ratio} ${params?.data?.available_units?.[0]} ` : params?.data?.available_units?.[1]
                        ) : params?.data?.unit}
                    </Typography>
                </>
            )
        },

    },
    {
        id: 'quantity',
        numeric: true,
        label: "כמות",
        align: 'center',
        type: 'number',
        filterable: false,
        sortable: true,
        editable: true,
    },
    {
        id: 'quantity_unit',
        numeric: true,
        label: "כמות ביח",
        align: 'center',
        type: 'number',
        filterable: false,
        sortable: true,
        editable: true,
        hide:true,
    },
    {
        id: 'quantityReturns',
        numeric: true,
        label: "החזרות",
        align: 'center',
        type: 'number',
        filterable: false,
        priority: -1,
        sortable: true,
        editable: true,
    },
    {
        id: 'totalPrice',
        numeric: false,
        label: 'מחיר',
        align: 'center',
        type: 'string',
        editable: true,
        dataFormat: "currency",
        valueLimit: {
            min: 0,
        }
    },
    {
        id: 'discount',
        numeric: false,
        label: "הנחה",
        hide: true,
        align: 'center',
        type: 'string',
        filterable: false,
        editable: true,
        dataFormat: "percent",
        valueLimit: {
            max: 100,
        }
    }
];

const CustomerProductsList = ({
    products,
    onProductChange,
    clearFunc,
    withReturns,
    getAllProducts,
    setGetAllProducts
}: { products: IItem[]; onProductChange: (product: IItem) => void, clearFunc: () => void, withReturns: boolean, getAllProducts: boolean, setGetAllProducts: React.Dispatch<React.SetStateAction<boolean>> }) => {

    const editCallback = useCallback((params) => {
        if (!params?.newValue) return;
        switch (params?.colDef?.headerName) {
            case 'כמות':
                onProductChange({
                    ...params.data,
                    quantity: roundDecimals(Number(params?.newValue), 4)
                })
                break;
            case 'כמות ביח':
                if(params?.data?.in_unit??0 >0){
                    onProductChange({
                        ...params.data,
                        quantity: roundDecimals(Number(params?.newValue)/params?.data?.in_unit, 8)
                    })
                }

                break;
            case 'מידה':
                onProductChange({
                    ...params.data,
                    quantitySecond: roundDecimals(Number(params?.newValue), 4)
                })
                break;
            case 'מחיר':
                onProductChange({
                    ...params.data,
                    totalPrice: roundDecimals(Number(params?.newValue), 4)
                })
                break;
            case 'החזרות':
                onProductChange({
                    ...params.data,
                    quantityReturns: roundDecimals(Number(params?.newValue), 4)
                })
                break;
            case 'הנחה':
                onProductChange({
                    ...params.data,
                    discount: roundDecimals(Number(params?.newValue), 4)
                })
                break;
        }
    }, [onProductChange]);

    const tableControls: ITableControls = {
        tableName: "customer/customer-list/customer/products",
        sidebar: true,
        selection: {
            enable: true,
            mode: "multiple"
        },
        extractId: (row) => {
            return row?.data?.id
        },
        manyEditTime:10,
        disableDefaultEditActions: true,
        cellRenderer: (params, columns) => {
            const row = params.data;
            if (params.node.rowPinned) return params.value || '';
            if (columns.cellRenderer) {
                return columns.cellRenderer({
                    params,
                });
            }
            if (typeof params?.value === "object") {
                const value = params?.value?.value || "";
                return value;
            }
            return params.value || '';
        },
    }
    const addDataAction = useMemo(() => {
        return {
            tooltip: "הצג את כל המוצרים",
            label: "כל המוצרים",
            component(tableApi) {

                return (
                    <Tooltip
                        title="הצג את כל המוצרים"
                        placement="top"
                    >
                        <Chip
                            icon={<AllInclusive />}
                            label="כל המוצרים"
                            onClick={() => {
                                setGetAllProducts(!getAllProducts)
                            }}
                            color={getAllProducts ? "primary" : "default"}
                        />
                    </Tooltip>
                )
            },
        }
    }, [getAllProducts]);
    return (
        <AgTable pinnedControl={basicPinnedControl()} addDataAction={addDataAction} columns={columns} data={products} tableControls={tableControls} customeCancelEdit={() => clearFunc()} editCallback={editCallback}/>
    );
};

export default memo(CustomerProductsList);
