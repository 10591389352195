import JSDB, {ApiCall, NKTypeConvertor} from '../../fromKotlin/nk';

import {dateObjectToString, roundDecimals} from '../../fromKotlin/nkutils';

import {AmountDataHolder} from '../../types/amountDataHolder';
import {debtDataHolder, EntType} from '../../types/debtDataHolder';
import {ExtendedDocument} from '../../types/DocumentsTypes/Document';
import {supDebtDataHolder} from '../../types/supDebtDataHolder';
import {visitDataHolder} from '../../types/visitDataHolder';
import {NewAmountDataHolder} from "../../types/newAmountDataHolder";
import {CompareDataHolder} from "../../types/compareDataHolder";
import {sum} from "lodash";
import {toArrayOrEmpty} from "../../types/DocumentsTypes/utils";
import {getBaseOrder} from "./documents.api";


// YOTAM
export const groupBy: (array, f) => any = (array, f) => {
    const groups = {};
    array.forEach((o) => {
        const group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return groups;
};
export const groupByUnStr: (array, f) => any = (array, f) => {
    const groups = {};
    array.forEach((o) => {
        const group = (f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return groups;
};
export const groupByKey: (o, f) => any = (o, f) => JSON.stringify(f(o));
export const groupBSec: (array, f) => any = (array, f) => [...array.reduce((r, o) => {
    const key = JSON.stringify(f(o));

    const item = r.get(key) || ({
        ...o, used: 0,
        instances: 0
    });

    item.used += o.used;
    item.instances += o.instances;

    return r.set(key, item);
}, new Map).values()];
export const groupMaxBy: (array, f, maxBy) => any = (array, f, maxBy) => {
    const groups = {};
    array.forEach((o) => {
        const group = JSON.stringify(f(o));
        const value = maxBy(o);
        if (group in groups) {
            if (value > groups[group]) {
                groups[group] = value;
            }
        } else {
            groups[group] = value;
        }
    });
    return groups;
};

export interface CostData {
    id: number;
    name: string;
    value: number;


}

export interface AdditionalCost {
    name: string;
    // p_id: number; ....

}

export const splitByDrivers: (data: AmountDataHolder[]) => AmountDataHolder[] = (data) => {
    const groups = groupBy(data, (item) => [item.driver]);
    return Object.keys(groups).map((group) => {
        const sub = groups[group];
        const driver = sub[0].driver;
        const driver_id = sub[0].driver_id;
        return {
            id: driver_id,
            nkObject: null,
            type: 'agent',
            name: driver,
            business_id: '',
            external_id: '',
            driver: undefined,
            collector: undefined,
            amount: roundDecimals(sub?.reduce((sum, x) => sum + roundDecimals(x.amount, 2), 0), 2),
            returns: roundDecimals(sub?.reduce((sum, x) => sum + roundDecimals(x.returns, 2), 0), 2),
            total_money: roundDecimals(sub?.reduce((sum, x) => sum + roundDecimals(x.total_money, 2), 0), 2),
            total_tax: roundDecimals(sub?.reduce((sum, x) => sum + roundDecimals(x.total_tax, 2), 0), 2),
            total_money_with: roundDecimals(sub?.reduce((sum, x) => sum + roundDecimals(x.total_money_with, 2), 0), 2),
            visitTime: roundDecimals(sub?.reduce((sum, x) => sum + (x.visitTime ?? 0), 0), 2),
            gain: roundDecimals(sub?.reduce((sum, x) => sum + (x.gain ?? 0), 0), 2),
            cost_before: roundDecimals(sub?.reduce((sum, x) => sum + (x.cost_before ?? 0), 0), 2),
            cost_after: roundDecimals(sub?.reduce((sum, x) => sum + (x.cost_after ?? 0), 0), 2),
            sub: sub
        };
    });
};

export const amountQDeserializeS2P: (ret: any, toDate: String) => AmountDataHolder[] = (ret, toDate) => {
    if (ret.length > 0) {
        const groups = groupBy(ret, (item) => [item.byEnt]);

        const citys = new Set();
        const agents = new Set();

        return Object.keys(groups).map((group) => {
            const items = groups[group];
            const c = JSDB().getSupplier(items[0].byEnt).first;
            const city = c.getCity(toDate);
            citys.add(city);
            const d = JSDB().dataQueryAggregate(NKTypeConvertor().toKotlinList(items), true);
            const sub = items.map((q) => {
                const byProduct = q.byProduct;
                const p = JSDB().getSupplierProduct(byProduct).first;
                // const cost_returns = p.getCostIncludeReturns();
                return {
                    id: byProduct,
                    nkObject: null,
                    type: 'product',
                    name: p.getName(),
                    barcode: p.getBarcode(),
                    position: p.getPosition(),
                    category: p.getCategory(),
                    amount_disc: p.getOrderProduct()?.getExtraUnitName() ?? "",
                    amount: roundDecimals(q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0), 2),
                    returns: roundDecimals(q.returnSum + ((q.returnSumSecJoin) ? q.returnSumSecJoin : 0), 2),
                    total_money_with: roundDecimals(q.getTotalBeforeTax() + q.getTotalTax(), 2),
                    total_money: roundDecimals(q.getTotalBeforeTax(), 2),
                    visitTime: q.number_of_rows,
                    total_tax: roundDecimals(q.getTotalTax(), 2)
                };
            }).sort((p) => p.position);
            return {
                id: c.id,
                nkObject: null,
                type: 'client',
                name: c.getName(),
                business_id: c.getBusinessId(toDate),
                external_id: c.getExternalId(),
                amount_disc: "",
                amount: roundDecimals(d.valueSum + ((d.valueSumSecJoin) ? d.valueSumSecJoin : 0), 2),
                returns: roundDecimals(d.returnSum + ((d.returnSumSecJoin) ? d.returnSumSecJoin : 0), 2),
                total_money: roundDecimals(d.getTotalBeforeTax(), 2),
                total_tax: roundDecimals(d.getTotalTax(), 2),
                total_money_with: roundDecimals(d.getTotalBeforeTax() + d.getTotalTax(), 2),
                visitTime: d.number_of_rows,
                sub: sub,
                city: Array.from(citys) as string[],
                agent: Array.from(agents) as string[]
            };
        });
    }
    return [];

};

export const amountQSupDeserialize: (ret: any, toDate: String, costMapping?: any) => AmountDataHolder[] = (ret, toDate, costMapping) => {
    if (ret.length > 0) {
        const groups = groupBy(ret, (item) => [item.byProduct]);

        return Object.keys(groups).map((group) => {

            const items = groups[group];
            const byProduct = items[0].byProduct;
            const p = JSDB().getSupplierProduct(byProduct).first;
            const d = JSDB().dataQueryAggregate(NKTypeConvertor().toKotlinList(items));
            const citys = new Set();
            const agents = new Set();
            // const cost_returns = p.getCostIncludeReturns();
            const sub = items.map((q) => {
                const c = JSDB().getSupplier(q.byEnt).first;
                const city = c.getCity(toDate);
                citys.add(city);
                return {
                    id: q.byEnt,
                    nkObject: null,
                    type: 'client',
                    name: c.getName(),
                    amount_disc: "",
                    amount_second: roundDecimals(((q.valueSumSec) ? q.valueSumSec : 0), 2),
                    amount: roundDecimals(q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0), 2),
                    returns: roundDecimals(q.returnSum + ((q.returnSumSecJoin) ? q.returnSumSecJoin : 0), 2),
                    total_money: roundDecimals(q.getTotalBeforeTax(), 2),
                    total_tax: roundDecimals(q.getTotalTax(), 2),
                    total_money_with: roundDecimals(q.getTotalBeforeTax() + q.getTotalTax(), 2),
                    visitTime: q.number_of_rows,
                    sub: []
                };
            });
            return {
                id: byProduct,
                nkObject: null,
                type: 'product',
                name: p.getName(),
                barcode: p.getBarcode(),
                position: p.getPosition(),
                category: p.getCategory(),
                amount_disc: p.getOrderProduct()?.getExtraUnitName() ?? "",
                amount_second: roundDecimals(((d.valueSumSec) ? d.valueSumSec : 0), 2),
                amount: roundDecimals(d.valueSum + ((d.valueSumSecJoin) ? d.valueSumSecJoin : 0), 2),
                returns: roundDecimals(d.returnSum + ((d.returnSumSecJoin) ? d.returnSumSecJoin : 0), 2),
                total_money: roundDecimals(d.getTotalBeforeTax(), 2),
                total_money_with: roundDecimals(d.getTotalBeforeTax() + d.getTotalTax(), 2),
                total_tax: roundDecimals(d.getTotalTax(), 2),
                visitTime: d.number_of_rows,
                city: Array.from(citys) as string[],
                agent: Array.from(agents) as string[],
                sub
            };
        });
    }
    return [];

};
export const amountQDeserializeC2P: (ret: any, toDate: String, costMapping?: any) => AmountDataHolder[] = (ret, toDate, costMapping) => {
    if (ret.length > 0) {
        const groups = groupBy(ret, (item) => [item.byEnt]);

        const citys = new Set();
        const agents = new Set();

        return Object.keys(groups).map((group) => {
            const items = groups[group];
            const c = JSDB().getClient(items[0].byEnt).first;
            const city = c.getCity(toDate);
            citys.add(city);
            const agent = c.getAgent(toDate);
            agents.add(agent);
            const driver = c.getDriverFinder(toDate)?.id;
            const d = JSDB().dataQueryAggregate(NKTypeConvertor().toKotlinList(items), true);
            const extra_cost_driver = costMapping && costMapping[driver] ? costMapping[driver] : 0;
            const extra_cost = costMapping && costMapping[-1] ? costMapping[-1] : 0;
            const sub = items.map((q) => {
                const byProduct = q.byProduct;
                const p = JSDB().getClientProduct(byProduct).first;
                // const cost_returns = p.getCostIncludeReturns();
                const cost_returns = p.cost_include_returns;
                return {
                    id: byProduct,
                    nkObject: null,
                    type: 'product',
                    name: p.getName(),
                    barcode: p.getBarcode(),
                    position: p.getPosition(),
                    category: p.getCategory(),
                    amount_disc: p.getOrderProduct()?.getExtraUnitName() ?? "",
                    amount_second: roundDecimals(((q.valueSumSec) ? q.valueSumSec : 0), 2),
                    amount: roundDecimals(q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0), 2),
                    returns: roundDecimals(q.returnSum + ((q.returnSumSecJoin) ? q.returnSumSecJoin : 0), 2),
                    total_money_with: roundDecimals(q.getTotalBeforeTax() + q.getTotalTax(), 2),
                    total_money: roundDecimals(q.getTotalBeforeTax(), 2),
                    gain: roundDecimals(q.getTotalCost() != 0 || (cost_returns) * q.getTotalCostRet() != 0 || extra_cost_driver || extra_cost ? ((q.getTotalBeforeTax() - (q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0)) * (extra_cost_driver + extra_cost)) - q.getTotalCost() + (1 - cost_returns) * q.getTotalCostRet()) : 0, 2),
                    cost_before: roundDecimals(q.getTotalCost() != 0 || (cost_returns) * q.getTotalCostRet() != 0 ? (-q.getTotalCost() + (1 - cost_returns) * q.getTotalCostRet()) : 0, 2),
                    cost_after: roundDecimals(costMapping && (extra_cost_driver || extra_cost) ? (-(q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0)) * (extra_cost_driver + extra_cost)) : 0, 2),
                    visitTime: q.number_of_rows,
                    total_tax: roundDecimals(q.getTotalTax(), 2)
                };

            }).sort((p) => p.position);
            const secName = sub ? new Set(sub.map((s) => s.amount_disc)) : undefined

            return {
                id: c.id,
                nkObject: null,
                type: 'client',
                name: c.getName(),
                business_id: c.getBusinessId(toDate),
                external_id: c.getExternalId(),
                driver: c.getDriverFinder(toDate)?.user_name,
                driver_id: c.getDriverFinder(toDate)?.id,
                collector: c.getCollector()?.user_name,
                amount_disc: secName && secName.size == 1 ? [...secName][0] : "",
                amount_second: roundDecimals(((d.valueSumSec) ? d.valueSumSec : 0), 2),
                amount: roundDecimals(d.valueSum + ((d.valueSumSecJoin) ? d.valueSumSecJoin : 0), 2),
                returns: roundDecimals(d.returnSum + ((d.returnSumSecJoin) ? d.returnSumSecJoin : 0), 2),
                total_money: roundDecimals(d.getTotalBeforeTax(), 2),
                total_tax: roundDecimals(d.getTotalTax(), 2),
                total_money_with: roundDecimals(d.getTotalBeforeTax() + d.getTotalTax(), 2),
                visitTime: d.number_of_rows,
                gain: roundDecimals(sub?.reduce((sum, x) => sum + (x.gain ?? 0), 0), 2),
                cost_before: roundDecimals(sub?.reduce((sum, x) => sum + (x.cost_before ?? 0), 0), 2),
                cost_after: costMapping ? roundDecimals(sub?.reduce((sum, x) => sum + (x.cost_after ?? 0), 0), 2) : 0,
                sub: sub,
                city: Array.from(citys) as string[],
                agent: Array.from(agents) as string[]
            };
        });
    }
    return [];

};
export const newSupAmountQDeserialize: (ret: any, toDate: String) => NewAmountDataHolder[] = (ret, toDate) => {
    if (ret.length > 0) {
        return ret.map((r, index) => {
            const p = JSDB().getSupplierProduct(r.byProduct).first;
            const c = JSDB().getSupplier(r.byEnt).first;
            const city = c.getCity(toDate);
            return {
                id: index,
                c_id: c.id,
                nkObject: null,
                type: 'supplier',
                name: c.getName(),
                product: p.getName(),
                business_id: c.getBusinessId(toDate),
                p_id: p.id,
                external_pid: p.getExternalId(),
                external_id: c.getExternalId(),
                barcode: p.getBarcode(),
                position: p.getPosition(),
                category: p.getCategory(),
                category2: p.getCategory2(),
                group: p.getCategory2(),
                amount_second: roundDecimals(((r.valueSumSec) ? r.valueSumSec : 0), 2),
                amount: roundDecimals(r.valueSum + ((r.valueSumSecJoin) ? r.valueSumSecJoin : 0), 2),
                returns: roundDecimals(r.returnSum + ((r.returnSumSecJoin) ? r.returnSumSecJoin : 0), 2),
                total_money: roundDecimals(r.getTotalBeforeTax(), 2),
                total_tax: roundDecimals(r.getTotalTax(), 2),
                total_money_with: roundDecimals(r.getTotalBeforeTax() + r.getTotalTax(), 2),
                visitTime: r.number_of_rows,
                city: city,
                sub: []
            };
        })
    }
    return [];
};

export const newAmountQDeserialize: (ret: any, toDate: String, costMapping?: any, additionalCost?: any, orderMap?: any, mecherMap?: any) => NewAmountDataHolder[] = (ret, toDate, costMapping, additionalCost, orderMap, mecherMap) => {
    if (ret.length > 0) {
        const beenMap = {}
        const returnArray = ret.map((r, index) => {
            const p = JSDB().getClientProduct(r.byProduct).first;
            const c = JSDB().getClient(r.byEnt).first;
            const month = `${r.byMonth ?? ''} - ${r.byYear ?? ''}`
            const city = c.getCity(toDate);
            const agent = c.getAgent(toDate);
            const cost_returns = p.cost_include_returns;
            const driver = c.getDriverFinder(toDate)?.id;
            const extra_cost_driver = costMapping && costMapping[driver] ? costMapping[driver] : 0;
            const extra_cost = costMapping && costMapping[-1] ? costMapping[-1] : 0;
            const multiplier = p.getOrderProduct()?.conversion_rate ?? 0
            const secondForce = multiplier == 0 ? 0 : (roundDecimals(r.valueSum + ((r.valueSumSecJoin) ? r.valueSumSecJoin : 0), 4) / multiplier)
            const order = orderMap && orderMap[`${r.byEnt}-${r.byProduct}`] ? orderMap[`${r.byEnt}-${r.byProduct}`] : undefined
            const mecher = mecherMap && mecherMap[`${r.byEnt}-${r.byProduct}`] ? mecherMap[`${r.byEnt}-${r.byProduct}`] : undefined
            beenMap[`${r.byEnt}-${r.byProduct}`] = true
            const data = {
                id: index,
                c_id: c.id,
                nkObject: c,
                type: 'client',
                month: month,
                name: c.getName(),
                product: p.getName(),
                unit: p.getUnitName(),
                second_unit: p.getOrderProduct()?.getExtraUnitName() ?? '',
                conversion_rate: multiplier,
                business_id: c.getBusinessId(toDate),
                p_id: p.id,
                external_pid: p.getExternalId(),
                external_id: c.getExternalId(),
                driver: c.getDriverFinder(toDate)?.user_name,
                driver_id: c.getDriverFinder(toDate)?.id,
                collector: c.getCollector()?.user_name,
                barcode: p.getBarcode(),
                position: p.getPosition(),
                category: p.getCategory(),
                category2: p.getCategory2(),
                group: p.getCategory2(),
                amount_second: roundDecimals(((r.valueSumSec) ? r.valueSumSec : 0), 2),
                secondForce: roundDecimals(secondForce, 2),
                amount: roundDecimals(r.valueSum + ((r.valueSumSecJoin) ? r.valueSumSecJoin : 0), 2),
                returns: roundDecimals(r.returnSum + ((r.returnSumSecJoin) ? r.returnSumSecJoin : 0), 2),
                total_money: roundDecimals(r.getTotalBeforeTax(), 2),
                total_tax: roundDecimals(r.getTotalTax(), 2),
                total_money_with: roundDecimals(r.getTotalBeforeTax() + r.getTotalTax(), 2),

                cost_before: roundDecimals(r.getTotalCost() != 0 || (cost_returns) * r.getTotalCostRet() != 0 ? (r.getTotalCost()) : 0, 2),
                cost_return: roundDecimals((cost_returns) * r.getTotalCostRet(), 2),
                cost_driver: roundDecimals(costMapping && (extra_cost_driver) ? (-(r.valueSum + ((r.valueSumSecJoin) ? r.valueSumSecJoin : 0)) * (extra_cost_driver)) : 0, 2),
                cost_after: roundDecimals(costMapping && (extra_cost) ? (-(r.valueSum + ((r.valueSumSecJoin) ? r.valueSumSecJoin : 0)) * (extra_cost)) : 0, 2),
                visitTime: r.number_of_rows,
                order_amount: order ? (roundDecimals(order.valueSum + ((order.valueSumSecJoin) ? order.valueSumSecJoin : 0), 2)) : undefined,
                order_second_amount: order ? (roundDecimals(((order.valueSumSec) ? order.valueSumSec : 0), 2)) : undefined,
                orderMulty: order ? (order.multiplyValue) : undefined,
                mecher: mecher ? mecher.value : undefined,
                mecherSub: mecher ? roundDecimals(r.valueSum + ((r.valueSumSecJoin) ? r.valueSumSecJoin : 0), 2) - mecher.value : undefined,
                city: city,
                agent: agent,
                sub: []
            }
            let totalCost = 0
            additionalCost.forEach((curCost, i) => {

                data[`cost_${i + 1}`] = curCost && curCost[`p_${p.id}`] ? roundDecimals(curCost[`p_${p.id}`] * data.amount, 2) : undefined
                totalCost += data[`cost_${i + 1}`] ?? 0
            })
            data.gain = roundDecimals((r.getTotalCost() != 0 || (cost_returns) * r.getTotalCostRet() != 0 || extra_cost_driver || extra_cost ? ((r.getTotalBeforeTax() - (r.valueSum + ((r.valueSumSecJoin) ? r.valueSumSecJoin : 0)) * (extra_cost_driver + extra_cost)) - r.getTotalCost() + (1 - cost_returns) * r.getTotalCostRet()) : 0) - totalCost, 2)
            return data;
        })

        if (orderMap) {
            Object.keys(orderMap).forEach((o, ind) => {
                const order = orderMap[o]

                if (!beenMap[`${order.byEnt}-${order.byProduct}`]) {
                    const p = JSDB().getClientProduct(order.byProduct).first;
                    const c = JSDB().getClient(order.byEnt).first;
                    const month = ``
                    const city = c.getCity(toDate);
                    const agent = c.getAgent(toDate);
                    const multiplier = p.getOrderProduct()?.conversion_rate ?? 0
                    const order2 = orderMap && orderMap[`${order.byEnt}-${order.byProduct}`] ? orderMap[`${order.byEnt}-${order.byProduct}`] : undefined
                    const mecher = mecherMap && mecherMap[`${order.byEnt}-${order.byProduct}`] ? mecherMap[`${order.byEnt}-${order.byProduct}`] : undefined
                    beenMap[`${order.byEnt}-${order.byProduct}`] = true
                    const data = {
                        id: returnArray.length + 1,
                        c_id: c.id,
                        nkObject: c,
                        type: 'client',
                        month: month,
                        name: c.getName(),
                        product: p.getName(),
                        unit: p.getUnitName(),
                        second_unit: p.getOrderProduct()?.getExtraUnitName() ?? '',
                        conversion_rate: multiplier,
                        business_id: c.getBusinessId(toDate),
                        p_id: p.id,
                        external_pid: p.getExternalId(),
                        external_id: c.getExternalId(),
                        driver: c.getDriverFinder(toDate)?.user_name,
                        driver_id: c.getDriverFinder(toDate)?.id,
                        collector: c.getCollector()?.user_name,
                        barcode: p.getBarcode(),
                        position: p.getPosition(),
                        category: p.getCategory(),
                        category2: p.getCategory2(),
                        group: p.getCategory2(),
                        amount_second: 0,
                        secondForce: 0,
                        amount: 0,
                        returns: 0,
                        total_money: 0,
                        total_tax: 0,
                        total_money_with: 0,

                        cost_before: 0,
                        cost_return: 0,
                        cost_driver: 0,
                        cost_after: 0,
                        visitTime: 1,
                        order_amount: order2 ? (roundDecimals(order2.valueSum + ((order2.valueSumSecJoin) ? order2.valueSumSecJoin : 0), 2) * order2.multiplyValue) : undefined,
                        order_second_amount: order2 ? (roundDecimals(((order2.valueSumSec) ? order2.valueSumSec : 0), 2) * order2.multiplyValue) : undefined,
                        orderMulty: order2 ? (order2.multiplyValue) : undefined,
                        mecher: mecher ? mecher.value : undefined,
                        mecherSub: mecher ? -mecher.value : undefined,
                        city: city,
                        agent: agent,
                        sub: []

                    }
                    returnArray.push(data)
                }
            })
        }
        if (mecherMap) {
            Object.keys(mecherMap).forEach((m, ind) => {
                const mecher = mecherMap[m]
                if (!beenMap[`${mecher.client_id}-${mecher.product_id}`]) {
                    const p = JSDB().getClientProduct(mecher.product_id).first;
                    const c = JSDB().getClient(mecher.client_id).first;
                    const month = ``
                    const city = c.getCity(toDate);
                    const agent = c.getAgent(toDate);
                    const multiplier = p.getOrderProduct()?.conversion_rate ?? 0
                    const order = orderMap && orderMap[`${mecher.client_id}-${mecher.product_id}`] ? orderMap[`${mecher.client_id}-${mecher.product_id}`] : undefined
                    const mecher2 = mecherMap && mecherMap[`${mecher.client_id}-${mecher.product_id}`] ? mecherMap[`${mecher.client_id}-${mecher.product_id}`] : undefined
                    beenMap[`${mecher.client_id}-${mecher.product_id}`] = true
                    const data = {
                        id: returnArray.length + 1,
                        c_id: c.id,
                        nkObject: c,
                        type: 'client',
                        month: month,
                        name: c.getName(),
                        product: p.getName(),
                        unit: p.getUnitName(),
                        second_unit: p.getOrderProduct()?.getExtraUnitName() ?? '',
                        conversion_rate: multiplier,
                        business_id: c.getBusinessId(toDate),
                        p_id: p.id,
                        external_pid: p.getExternalId(),
                        external_id: c.getExternalId(),
                        driver: c.getDriverFinder(toDate)?.user_name,
                        driver_id: c.getDriverFinder(toDate)?.id,
                        collector: c.getCollector()?.user_name,
                        barcode: p.getBarcode(),
                        position: p.getPosition(),
                        category: p.getCategory(),
                        category2: p.getCategory2(),
                        group: p.getCategory2(),
                        amount_second: 0,
                        secondForce: 0,
                        amount: 0,
                        returns: 0,
                        total_money: 0,
                        total_tax: 0,
                        total_money_with: 0,

                        cost_before: 0,
                        cost_return: 0,
                        cost_driver: 0,
                        cost_after: 0,
                        visitTime: 1,
                        order_amount: order ? (roundDecimals(order.valueSum + ((order.valueSumSecJoin) ? order.valueSumSecJoin : 0), 2)) : undefined,
                        order_second_amount: order ? (roundDecimals(((order.valueSumSec) ? order.valueSumSec : 0), 2)) : undefined,
                        orderMulty: order ? (order.multiplyValue) : undefined,
                        mecher: mecher2 ? mecher2.value : undefined,
                        mecherSub: mecher ? -mecher.value : undefined,
                        city: city,
                        agent: agent,
                        sub: []
                    }
                    returnArray.push(data)
                }
            })
        }
        return returnArray
    }
    return [];

};
export const adjustCompareValue: (ret: CompareDataHolder[], placements, toDate: String, timeDisplay) => any = (ret, placements, toDate, timeDisplay) => {

    const newComp = []
    const newPlacements = []
    const monthsHolder = []
    let cur = []
    let dfs = []
    placements.forEach((df, ind) => {
        if (timeDisplay === 'month') {
            monthsHolder.push([ind])
            newPlacements.push(df)
        } else if (timeDisplay === 'quarter') {
            if (ind % 3 === 0) {
                dfs = []
                cur = []
            }

            cur.push(ind)
            dfs.push(df)
            if (cur.length === 3) {
                monthsHolder.push(cur)
                newPlacements.push(dfs.join("-"))
            }
        } else if (timeDisplay === 'twoMonth') {
            if (ind % 2 === 0) {
                dfs = []
                cur = []
            }

            cur.push(ind)
            dfs.push(df)
            if (cur.length === 2) {
                monthsHolder.push(cur)
                newPlacements.push(dfs.join("-"))
            }
        } else if (timeDisplay === 'sixMonth') {
            if (ind % 6 === 0) {
                cur = []
                dfs = []
            }

            cur.push(ind)
            dfs.push(df)
            if (cur.length === 6) {

                monthsHolder.push(cur)
                newPlacements.push(dfs.join("-"))

            }
        }


    })
    ret.forEach((r, index) => {
        const newR = {}
        newR.id = r.id
        newR.nkObject = r.nkObject
        newR.name = r.name
        newR.driver = r.driver
        newR.business_id = r.business_id
        newR.city = r.city
        newR.agent = r.agent

        monthsHolder.forEach((m, ind) => {
            newR['amount_' + ind] = sum(m.map((i) => r['amount_' + i] ?? 0))
            newR['returns_' + ind] = sum(m.map((i) => r['returns_' + i] ?? 0))
            newR['dvs_' + ind] = sum(m.map((i) => r['dvs_' + i] ?? 0))
            newR['value_' + ind] = roundDecimals(sum(m.map((i) => r['value_' + i] ?? 0)), 2)


        })
        let prev = 0
        monthsHolder.forEach((m, indexx) => {
            const ind = monthsHolder.length - indexx - 1
            console.log(ind)
            let gain = 0
            if (prev == 0 && (newR['value_' + ind] ?? 0 > 0)) {
                gain = 0
            } else if (prev == 0 && (newR['value_' + ind] ?? 0 < 0)) {
                gain = 0
            } else if (prev < 0) {
                gain = 0
            } else if (prev != 0 && ((newR['value_' + ind] / prev) ?? 0 > 1)) {
                gain = roundDecimals(((newR['value_' + ind] / prev) - 1) * 100, 2)
            } else if (prev != 0 && ((newR['value_' + ind] / prev) ?? 0 < 1)) {
                gain = roundDecimals((1 - (newR['value_' + ind] / prev)) * 100, 2)
            }
            newR['gain_' + ind] = gain
            prev = newR['value_' + ind]

        })
        newComp.push(newR)
    })
    return {compare: newComp, date: newPlacements}

};
export const newCompareQDeserialize: (ret: any, placements, toDate: String, method) => CompareDataHolder[] = (ret, placements, toDate, method) => {
    const indexMap = {};
    placements.forEach((p, i) => {
        indexMap[p] = i;
    })

    if (ret.length > 0) {
        let result = []
        return ret.map((r, index) => {
            const c = JSDB().getClient(r.client_id).first;
            const city = c.getCity(toDate);
            const agent = c.getAgent(toDate);
            const driver = c.getDriverFinder(toDate)?.id;
            const res = {
                id: c.id,
                nkObject: r,
                name: c.getName(),
                driver: driver,
                business_id: c.getBusinessId(toDate),
                city: city,
                agent: agent,
            }
            let prev = 0
            r.data.toArray().forEach((e, i) => {

                let iter
                if (method == 0) {
                    iter = indexMap[e.month]
                } else if (method == 1) {
                    iter = indexMap[e.year]
                } else if (method == 2) {
                    iter = indexMap[e.week]
                }
                res['amount_' + iter] = roundDecimals(e?.valueSum, 1)
                res['return_' + iter] = roundDecimals(e?.returnSum, 1)
                res['dvs_' + iter] = undefined
                let gain = 0
                if (prev == 0 && (e?.totalMoney ?? 0 > 0)) {
                    gain = -100
                } else if (prev == 0 && (e?.totalMoney ?? 0 < 0)) {
                    gain = 0
                } else if (prev != 0 && (e?.totalMoney ?? 0 > 0)) {
                    gain = -roundDecimals(1 - e?.totalMoney / prev, 1) * 100
                } else if (prev != 0 && (e?.totalMoney ?? 0 < 0)) {
                    gain = roundDecimals(1 - e?.totalMoney / prev, 1) * 100
                }
                res['gain_' + iter] = gain
                res['value_' + iter] = roundDecimals(e?.totalMoney, 1)
                prev = roundDecimals(e?.totalMoney, 1)


            })
            return res
        })
    }
    return [];

};
export const amountQDeserialize: (ret: any, toDate: String, costMapping?: any) => AmountDataHolder[] = (ret, toDate, costMapping) => {
    if (ret.length > 0) {
        const groups = groupBy(ret, (item) => [item.byProduct]);

        return Object.keys(groups).map((group) => {

            const items = groups[group];
            const byProduct = items[0].byProduct;
            const p = JSDB().getClientProduct(byProduct).first;
            const d = JSDB().dataQueryAggregate(NKTypeConvertor().toKotlinList(items));
            const citys = new Set();
            const agents = new Set();
            // const cost_returns = p.getCostIncludeReturns();
            const cost_returns = p.cost_include_returns;
            const sub = items.map((q) => {
                const c = JSDB().getClient(q.byEnt).first;
                const city = c.getCity(toDate);
                citys.add(city);
                const agent = c.getAgent(toDate);
                agents.add(agent);
                const driver = c.getDriverFinder(toDate)?.id;
                const extra_cost_driver = costMapping && costMapping[driver] ? costMapping[driver] : 0;
                const extra_cost = costMapping && costMapping[-1] ? costMapping[-1] : 0;

                return {
                    id: q.byEnt,
                    nkObject: null,
                    type: 'client',
                    name: c.getName(),
                    amount_second: roundDecimals(((q.valueSumSec) ? q.valueSumSec : 0), 2),
                    amount: roundDecimals(q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0), 2),
                    returns: roundDecimals(q.returnSum + ((q.returnSumSecJoin) ? q.returnSumSecJoin : 0), 2),
                    total_money: roundDecimals(q.getTotalBeforeTax(), 2),
                    total_tax: roundDecimals(q.getTotalTax(), 2),
                    total_money_with: roundDecimals(q.getTotalBeforeTax() + q.getTotalTax(), 2),
                    gain: roundDecimals(q.getTotalCost() != 0 || (cost_returns) * q.getTotalCostRet() != 0 || extra_cost_driver || extra_cost ? ((q.getTotalBeforeTax() - (q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0)) * (extra_cost_driver + extra_cost)) - q.getTotalCost() + (1 - cost_returns) * q.getTotalCostRet()) : 0, 2),
                    cost_before: roundDecimals(q.getTotalCost() != 0 || (cost_returns) * q.getTotalCostRet() != 0 ? (-q.getTotalCost() + (1 - cost_returns) * q.getTotalCostRet()) : 0, 2),
                    cost_after: roundDecimals(costMapping && (extra_cost_driver || extra_cost) ? (-(q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0)) * (extra_cost_driver + extra_cost)) : 0, 2),
                    visitTime: q.number_of_rows,
                    sub: []
                };
            });
            return {
                id: byProduct,
                nkObject: null,
                type: 'product',
                name: p.getName(),
                barcode: p.getBarcode(),
                position: p.getPosition(),
                category: p.getCategory(),
                amount_disc: p.getOrderProduct()?.getExtraUnitName() ?? "",
                amount_second: roundDecimals(((d.valueSumSec) ? d.valueSumSec : 0), 2),
                amount: roundDecimals(d.valueSum + ((d.valueSumSecJoin) ? d.valueSumSecJoin : 0), 2),
                returns: roundDecimals(d.returnSum + ((d.returnSumSecJoin) ? d.returnSumSecJoin : 0), 2),
                total_money: roundDecimals(d.getTotalBeforeTax(), 2),
                total_money_with: roundDecimals(d.getTotalBeforeTax() + d.getTotalTax(), 2),
                total_tax: roundDecimals(d.getTotalTax(), 2),
                gain: roundDecimals(sub?.reduce((sum, x) => sum + (x.gain ?? 0), 0), 2),
                cost_before: roundDecimals(sub?.reduce((sum, x) => sum + (x.cost_before ?? 0), 0), 2),
                cost_after: costMapping ? roundDecimals(sub?.reduce((sum, x) => sum + (x.cost_after ?? 0), 0), 2) : 0,
                visitTime: d.number_of_rows,
                city: Array.from(citys) as string[],
                agent: Array.from(agents) as string[],
                sub
            };
        });
    }
    return [];

};
export const getDataStatistics: (toDate: Date, ids?: number[], fromDate?: Date) => Promise<AmountDataHolder[]> = async (toDate, ids, fromDate) => {


    const req = new ApiCall('GET_ORDER_STATISTICS');
    if (fromDate)
        req.putParam('from', dateObjectToString(fromDate));
    req.putParam('to', dateObjectToString(toDate));
    req.putParam('withLast', 3);

    req.putParam('ids', NKTypeConvertor().toKotlinList(ids ?? [-1]));
    req.putParam('month_counter', 1);


    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    let arr;
    if (result.first.size > 0) {
        arr = groupBy(result.first.toArray(), (item) => item.id);
        ;
    } else {
        return {};
    }
    return (arr);

};
export const getClientVisitDistinct: (fromDate: Date, toDate: Date, ids?: number[],) => Promise<AmountDataHolder[]> = async (fromDate, toDate, ids) => {


    const req = new ApiCall('GET_CLIENT_VISIT_DISTINCT');
    req.putParam('fromDate', dateObjectToString(fromDate));
    req.putParam('toDate', dateObjectToString(toDate));

    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids ?? [-1]));


    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    let arr;

    if (result.first.size > 0) {
        arr = groupBy(result.first.toArray(), (item) => item.ent_id);
        ;
    } else {
        return {};
    }

    return (arr);

};

export const debtQDeserialize: (ret: any, toDate: String, dayOrder?: ExtendedDocument[]) => debtDataHolder[] = (ret, toDate, dayOrder) => {
    if (ret.length > 0) {

        const activeClients = ret;
        const withBranch = activeClients.filter((a) => a.branch > -1);
        const withoutBranch = activeClients.filter((a) => a.branch == -1 && (JSDB().getClient(a.client_id).first.active == 1 || Math.abs(a.positive_carteset - a.negative_carteset) > 0.9));

        const groupsBranched = groupBy(withBranch, (item) => [item.branch]);
        let sdo = {}
        if (dayOrder)
            sdo = groupByUnStr(dayOrder, (item) => Number(item.entId));

        const branchedArray = Object.keys(groupsBranched).map((group) => {
            const item = groupsBranched[group];
            const clients = item.map((x) => (x.client_id));
            const client = JSDB().getClient(item[0].client_id).first
            const c = client.masterBranch ?? client
                console.log(sdo[c.id])
            const id = c.id;
            return {
                id: id,
                nkObject: c,
                name: c.getBusinessName(),
                Bname: c.getBusinessName(),
                amount_dv: roundDecimals(item?.reduce((sum, x) => sum + (x.notes_amount ?? 0), 0), 2),
                value_dv: roundDecimals(item?.reduce((sum, x) => sum + (x.notes ?? 0), 0), 2),
                amount_tn: roundDecimals(item?.reduce((sum, x) => sum + (x.taxNotes_amount ?? 0), 0), 2),
                notes_open: roundDecimals(item?.reduce((sum, x) => sum + (x.notes_open ?? 0), 0), 2),
                taxNotes_open: roundDecimals(item?.reduce((sum, x) => sum + (x.taxNotes_open ?? 0), 0), 2),
                value_tn: roundDecimals(item?.reduce((sum, x) => sum + (x.taxNotes ?? 0), 0), 2),
                amount_pay: roundDecimals(item?.reduce((sum, x) => sum + (x.pay_notes_amount ?? 0), 0), 2),
                value_pay: roundDecimals(item?.reduce((sum, x) => sum + (x.pay_notes ?? 0), 0), 2),
                value_carteset: roundDecimals(item?.reduce((sum, x) => sum + ((x.positive_carteset - x.negative_carteset) ?? 0), 0), 2),
                openSumDue: roundDecimals(item?.reduce((sum, x) => sum + (x.openSumDue ?? 0), 0), 2),
                overdue_tax_notes : roundDecimals(item?.reduce((sum, x) => sum + (x.overdue_tax_notes ?? 0), 0), 2),
                openSumDueMonth: roundDecimals(item?.reduce((sum, x) => sum + (x.openSumDueMonth ?? 0), 0), 2),
                overdueMonth : roundDecimals(item?.reduce((sum, x) => sum + (x.overdueMonth ?? 0), 0), 2),
                value_left: roundDecimals(item?.reduce((sum, x) => sum + (x.left ?? 0), 0), 2),
                mdate_carteset:item?.[0]?.mdate_carteset,
                net: item.length,
                city: c.getCity(),
                comments: c.comments,
                cnotes2: c.notes2,
                cnotes3: c.notes3,
                cnotes4: c.notes4,
                phone: c.getPhone(),
                phone2: c.getSecondPhone(),
                phone3: c.getThirdPhone(),
                contact: c.getPhoneContact(),
                contact2: c.getSecondPhoneContact(),
                contact3: c.getThirdPhoneContact(),
                obligo: c.obligo,
                category: c.category,
                group: c.category2,
                payment_notes: c.payment_notes,
                days_to_pay: c.days_to_pay,
                date_contact: !c?.date_contact || c?.date_contact == '' ? '' : JSDB().getDatesManipulator().dateIsrael(c?.date_contact),
                agents: c.getAgent(),
                baseOrder: sdo[c.id]?.[0],
                baseValue: sdo[c.id]?.[0]?.preValue,
                clients: clients,

            };
        });

        const groupsNotBranched = groupBy(withoutBranch, (item) => [item.client_id]);

        const notBranchedArray = Object.keys(groupsNotBranched).map((group) => {
            const item = groupsNotBranched[group][0];
            const id = item.client_id;
            const c = JSDB().getClient(id).first;
            return {
                id: id,
                nkObject: c,
                name: c.getName(),
                Bname: c.getBusinessName(),
                amount_dv: roundDecimals(item.notes_amount),
                value_dv: roundDecimals(item.notes),
                amount_tn: roundDecimals(item.taxNotes_amount),
                notes_open: roundDecimals(item.notes_open),
                taxNotes_open: roundDecimals(item.taxNotes_open),
                value_tn: roundDecimals(item.taxNotes),
                amount_pay: roundDecimals(item.pay_notes_amount),
                value_pay: roundDecimals(item.pay_notes),
                value_carteset: roundDecimals(item.positive_carteset - item.negative_carteset),
                openSumDue: roundDecimals(item.openSumDue),
                overdue_tax_notes : roundDecimals(item.overdue_tax_notes),

                openSumDueMonth: roundDecimals(item.openSumDueMonth),
                overdueMonth : roundDecimals(item.overdueMonth),
                value_left: roundDecimals(item.left),
                mdate_carteset:item.mdate_carteset,
                city: c.getCity(),
                comments: c.comments,
                cnotes2: c.notes2,
                cnotes3: c.notes3,
                cnotes4: c.notes4,
                phone: c.getPhone(),
                phone2: c.getSecondPhone(),
                phone3: c.getThirdPhone(),
                contact: c.getPhoneContact(),
                contact2: c.getSecondPhoneContact(),
                contact3: c.getThirdPhoneContact(),
                obligo: c.obligo,
                category: c.category,
                group: c.category2,
                payment_notes: c.payment_notes,
                days_to_pay: c.days_to_pay,
                baseOrder: sdo[c.id]?.[0],
                date_contact: !c?.date_contact || c?.date_contact == '' ? '' : JSDB().getDatesManipulator().dateIsrael(c?.date_contact),
                agents: c.getAgent(),
                baseValue: sdo[c.id]?.[0]?.preValue,
            };
        });
        return [...branchedArray, ...notBranchedArray];
    }

    return [];

};


export const debtQDeserializeSup: (ret: any, toDate: String) => debtDataHolder[] = (ret, toDate) => {
    if (ret.length > 0) {

        const activeClients = ret;
        const withoutBranch = activeClients.filter((a) => a.branch == -1 && (JSDB().getSupplier(a.client_id).first.active == 1 || Math.abs(a.positive_carteset - a.negative_carteset) > 0.9));


        const groupsNotBranched = groupBy(withoutBranch, (item) => [item.client_id]);

        const notBranchedArray = Object.keys(groupsNotBranched).map((group) => {
            const item = groupsNotBranched[group][0];
            const id = item.client_id;
            const c = JSDB().getSupplier(id).first;
            return {
                id: id,
                nkObject: c,
                name: c.getName(),
                Bname: c.getBusinessName(),
                amount_dv: roundDecimals(item.notes_amount),
                value_dv: roundDecimals(item.notes),
                amount_tn: roundDecimals(item.taxNotes_amount),
                notes_open: roundDecimals(item.notes_open),
                taxNotes_open: roundDecimals(item.taxNotes_open),
                value_tn: roundDecimals(item.taxNotes),
                amount_pay: roundDecimals(item.pay_notes_amount),
                value_pay: roundDecimals(item.pay_notes),
                value_carteset: roundDecimals(item.positive_carteset - item.negative_carteset),
                openSumDue: roundDecimals(item.openSumDue),
                overdue_tax_notes : roundDecimals(item.overdue_tax_notes),
                value_left: roundDecimals(item.left),
                mdate_carteset:item.mdate_carteset,
                city: c.getCity(),
                comments: c.comments,
                cnotes2: c.notes2,
                cnotes3: c.notes3,
                cnotes4: c.notes4,
                phone: c.getPhone(),
                phone2: c.getSecondPhone(),
                phone3: c.getThirdPhone(),
                contact: c.getPhoneContact(),
                contact2: c.getSecondPhoneContact(),
                contact3: c.getThirdPhoneContact(),
                obligo: c.obligo,
                category: c.category,
                group: c.category2,
                payment_notes: c.payment_notes,
                days_to_pay: c.days_to_pay,
                date_contact: !c?.date_contact || c?.date_contact == '' ? '' : JSDB().getDatesManipulator().dateIsrael(c?.date_contact),
            };
        });
        return [...notBranchedArray];
    }

    return [];

};

export const refreshDebt: (ret: debtDataHolder[]) => debtDataHolder[] = (ret) => {
    return ret.map((item) => {
        const c = JSDB().getClient(item.id).first;
        return {
            ...item,
            city: c.getCity(),
            comments: c.comments,
            cnotes2: c.notes2,
            cnotes3: c.notes3,
            cnotes4: c.notes4,
            phone: c.getPhone(),
            phone2: c.getSecondPhone(),
            phone3: c.getThirdPhone(),
            contact: c.getPhoneContact(),
            contact2: c.getSecondPhoneContact(),
            contact3: c.getThirdPhoneContact(),
            obligo: c.obligo,
            category: c.category,
            days_to_pay: c.days_to_pay,
            group: c.category2,
            payment_notes: c.payment_notes,
            date_contact: !c?.date_contact || c?.date_contact == '' ? '' : JSDB().getDatesManipulator().dateIsrael(c?.date_contact),
            agents: c.getAgent()
        };
    })

};
export const visitQDeserialize: (ret: any, toDate: String, dates: string[]) => visitDataHolder[] = (ret, toDate, dates) => {
    if (ret.length > 0) {
        const groupsNotBranched = groupBy(ret, (item) => [item.client_id]);
        const notBranchedArray = Object.keys(groupsNotBranched).map((group) => {
            const item = groupsNotBranched[group][0];
            const id = item.client_id;
            const c = JSDB().getClient(id).first;
            const driver = c.getDriverFinder(toDate)?.user_name

            const res = {
                id: id,
                nkObject: c,
                name: c.getName(),
                driver: driver,
                type_comment: 'head',
                placeHolder: item.placeHolder,
                from_0: item.from,
                to_0: item.to,
                comments_0: item.comments?.replace("\\n", "\n"),
                dv_visiting_0: item.dv_visiting,
                dv_documents_0: item.dv_documents,
                tn_visiting_0: item.tn_visiting,
                tn_documents_0: item.tn_documents,
                pay_visiting_0: item.pay_visiting,
                pay_documents_0: item.pay_documents,
                visit_visiting_0: item.visit_visiting,
                visit_documents_0: item.visit_documents,
                valueSum_0: roundDecimals(item.valueSum),
                returnSum_0: roundDecimals(item.returnSum),
                valueSumSec_0: roundDecimals(item.valueSumSec),
                returnSumSec_0: roundDecimals(item.returnSumSec),
                total_visit_0: roundDecimals(item.total_visit),
                city: c.getCity(),
                agents: c.getAgent()
            }
            if (dates.length > 1) {
                const arr = item.data.toArray()
                dates.forEach((e, i) => {
                    const iter = i + 1
                    res['from_' + iter] = arr[i]?.start_week
                    res['to_' + iter] = arr[i]?.end_week
                    res['dv_visiting_' + iter] = arr[i]?.dv_visiting
                    res['dv_documents_' + iter] = arr[i]?.dv_documents
                    res['tn_documents_' + iter] = arr[i]?.tn_documents
                    res['tn_visiting_' + iter] = arr[i]?.tn_visiting
                    res['pay_visiting_' + iter] = arr[i]?.pay_visiting
                    res['pay_documents_' + iter] = arr[i]?.pay_documents
                    res['visit_visiting_' + iter] = arr[i]?.visit_visiting
                    res['visit_documents_' + iter] = arr[i]?.visit_documents
                    res['valueSum_' + iter] = arr[i]?.valueSum
                    res['returnSum_' + iter] = arr[i]?.returnSum
                    res['valueSumSec_' + iter] = arr[i]?.valueSumSec
                    res['returnSumSec_' + iter] = arr[i]?.returnSumSec
                    res['total_visit_' + iter] = arr[i]?.total_visit
                    res['comments_' + iter] = arr[i]?.comments?.replace("\\n", "\n")

                })
            }
            return res
        });
        return [...notBranchedArray];
    }

    return [];

};

export interface tempVisitHolder {

    visit: visitDataHolder[]
    date: string[]
}

export interface tempCompareHolder {

    compare: CompareDataHolder[]
    date: string[]
}

export const getClientCompare: (method: number, toDate: string, ids?: number, citys?: string[], agents?: string[], creators?: string[], drivers?: string[], category?: string[], cost?: boolean, timeFrame?: string) => Promise<tempCompareHolder> = async (method, toDate, ids, citys, agents, creators, drivers, category, cost, timeFrame) => {

    const req = new ApiCall('GET_CLIENT_COMPARE');
    req.putParam('date', (toDate));
    req.putParam('method', (method));
    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    if (cost)
        req.putParam('cost', true);
    if (creators?.length ?? 0 > 0) {
        req.putParam('agents', NKTypeConvertor().toKotlinList(creators));
    }
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    let arr;

    const date = (toDate);
    if (result.first.first.size > 0) {
        arr = result.first.first.toArray().filter((d) => {
            let res = true;

            if (citys && citys.length > 0 && d.byEnt) {
                const c = JSDB().getClient(d.byEnt).first.getCity(date);
                res = res && citys.some((city) => c.includes(city));

            }
            if (agents && agents.length > 0 && d.byEnt) {
                const agent = JSDB().getClient(d.byEnt).first.getAgent(date);
                res = res && agents.some((agen) => agent == (agen));
            }
            if (drivers && drivers.length > 0 && d.byEnt) {
                const agent = JSDB().getClient(d.byEnt).first.getDriverFinder(date)?.user_name;
                res = agent && res && drivers.some((agen) => agent == (agen));

            }
            if (category && category.length > 0 && d.byEnt) {
                const c = JSDB().getClient(d.byEnt).first.getCategory(date);
                res = res && category.some((city) => c.includes(city));

            }
            return res;

        });

    } else {
        return [];
    }
    const comp = newCompareQDeserialize(arr, result.first.second.toArray(), date, method);
    return {compare: comp, date: result.first.second.toArray()};

};

export const getClientGiul: (method: number, toDate: string, fromDate?:string,ids?: number, citys?: string[], agents?: string[], creators?: string[], drivers?: string[], category?: string[],num?:number) => Promise<tempCompareHolder> = async (method, toDate,fromDate, ids, citys, agents, creators, drivers, category, num) => {

    const req = new ApiCall('GET_CLIENT_GIUL_FAST');
    req.putParam('toDate', (toDate));
    req.putParam('date', (toDate));
    if (fromDate)
        req.putParam('fromDate', (fromDate));
    if(num)
        req.putParam('shrinkNum',num);

    req.putParam('open', true);
    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids));

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    let arr;

    const date = (toDate);
    if (result.first.size > 0) {
        arr = result.first.toArray().filter((d) => {
            let res = true;

            if (citys && citys.length > 0 && d.client_id) {
                const c = JSDB().getClient(d.client_id).first.getCity(date);
                res = res && citys.some((city) => c.includes(city));

            }
            if (agents && agents.length > 0 && d.client_id) {
                const agent = JSDB().getClient(d.client_id).first.getAgent(date);
                res = res && agents.some((agen) => agent == (agen));
            }
            if (drivers && drivers.length > 0 && d.client_id) {
                const agent = JSDB().getClient(d.client_id).first.getDriverFinder(date)?.user_name;
                res = agent && res && drivers.some((agen) => agent == (agen));

            }
            if (category && category.length > 0 && d.client_id) {
                const c = JSDB().getClient(d.client_id).first.getCategory(date);
                res = res && category.some((city) => c.includes(city));

            }
            return res;

        });

    } else {
        return [];
    }
   return arr.map((d) => {
           const c= JSDB().getClient(d.client_id).first
        return {
            name: c.getName(),
            external_id:c.getExternalId(),
            agent: c.getAgent(),
            city: c.getCity(),
            category: c.getCategory(),
            driver: c.getDriverFinder(date)?.user_name,
            id:c.id,
            totalValueSum: roundDecimals(d.totalValueSum??0),
            numOfOpen: d.numOfOpen,
            valueSum1: d.valueSum1? roundDecimals(d.valueSum1):undefined,
            openAmount1: d.openAmount1,
            valueSum2: d.valueSum2 ? roundDecimals(d.valueSum2):undefined,
            openAmount2: d.openAmount2,
            valueSum3: d.valueSum3 ? roundDecimals(d.valueSum3):undefined,
            openAmount3: d.openAmount3,
            valueSum4: d.valueSum4? roundDecimals(d.valueSum4):undefined,
            openAmount4: d.openAmount4,
            restTotalValueSum: d.restTotalValueSum ? roundDecimals(d.restTotalValueSum):undefined,
            restNumOpen: d.restNumOpen,
        };
    });


};
export const getClientVisitation: (fromDate: string, toDate: string, ids?: number, citys?: string[], agents?: string[], creators?: string[], drivers?: string[], category?: string[], tax_visitation?: boolean, note_visitation?: boolean, dv_visitation?: boolean) => Promise<tempVisitHolder> = async (fromDate, toDate, ids, citys, agents, creators, drivers, category, tax_visitation, note_visitation, dv_visitation) => {

    const req = new ApiCall('GET_CLIENT_VISIT_OPT');
    req.putParam('fromDate', (fromDate));
    req.putParam('toDate', (toDate));
    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids));

    req.putParam('tax_visitation', (tax_visitation == true));
    req.putParam('note_visitation', (note_visitation == true));
    req.putParam('dv_visitation', (dv_visitation == true));
    req.putParam('allClients', true);

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    let arr;

    const date = (toDate);
    const array = result.first.first
    const dates = result.first.second
    if (array.size > 0) {

        arr = array.toArray().filter((d) => {
            let res = true;
            if (d.client_id) {
                const client = JSDB().getClient(d.client_id).first
                const master = client
                if (citys && citys.length > 0) {
                    const c = master.getCity(date);
                    res = res && citys.some((city) => c.includes(city));

                }
                if (category && category.length > 0) {
                    const c = client.getCategory(date);
                    res = res && category.some((city) => c.includes(city));

                }
                if (agents && agents.length > 0) {
                    const agent = master.getAgent(date);
                    res = res && agents.some((agen) => agent == (agen));
                }
                if (drivers && drivers.length > 0) {
                    const agent = master.getDriverFinder(date)?.user_name;
                    res = agent && res && drivers.some((agen) => agent == (agen));

                }
            }

            return res;

        });
    } else {
        return {
            visit: [],
            date: []
        };
    }
    const visit = visitQDeserialize(arr, date, dates.toArray());
    return {
        visit: visit,
        date: dates.toArray()
    }
};


export const getClientDebt: (fromDate: string, toDate: string, ids?: number, citys?: string[], agents?: string[], creators?: string[], drivers?: string[]) => Promise<debtDataHolder[]> = async (fromDate, toDate, ids, citys, agents, creators, drivers) => {

    const req = new ApiCall('GET_CLIENT_DEBT');
    req.putParam('fromDate', (fromDate));
    req.putParam('toDate', (toDate));
    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids));

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    let arr;
    const date = (toDate);
    if (result.first.size > 0) {

        arr = result.first.toArray().filter((d) => {
            let res = true;
            if (d.client_id) {
                const client = JSDB().getClient(d.client_id).first
                const master = client.masterBranch ?? client
                if (citys && citys.length > 0) {
                    const c = master.getCity(date);
                    res = res && citys.some((city) => c.includes(city));

                }
                if (agents && agents.length > 0) {
                    const agent = master.getAgent(date);
                    res = res && agents.some((agen) => agent == (agen));
                }
                if (drivers && drivers.length > 0) {
                    const agent = master.getDriverFinder(date)?.user_name;
                    res = agent && res && drivers.some((agen) => agent == (agen));

                }
            }

            return res;

        });
    } else {
        return [];
    }
    let dayOrder = undefined
    if (JSDB().getCompany() == "alkimaycompany")
        dayOrder = await getBaseOrder(1,undefined,true);
    return debtQDeserialize(arr, date, dayOrder);
};


export const supDebtQDeserialize: (ret: any, toDate: String) => supDebtDataHolder[] = (ret, toDate) => {
    if (ret.length > 0) {
        const activeClients = ret.filter((a) => JSDB().getSupplier(a.id).first.active == 1);

        const groupsNotBranched = groupBy(activeClients, (item) => [item.id]);

        const notBranchedArray = Object.keys(groupsNotBranched).map((group) => {
            const item = groupsNotBranched[group][0];
            const id = item.id;

            const c = JSDB().getSupplier(id).first;

            return {
                id: id,
                nkObject: null,
                name: c.getName(),
                amount_dv: roundDecimals(0),
                value_dv: roundDecimals(item.value)
            };
        });

        return [...notBranchedArray];
    }

    return [];

};
export const getSupplierDebt: (fromDate: string, toDate: string, ids?: number, citys?: string[], agents?: string[], creators?: string[], drivers?: string[]) => Promise<debtDataHolder[]> = async (fromDate, toDate, ids, citys, agents, creators, drivers) => {

    const req = new ApiCall('GET_SUP_DEBT');
    req.putParam('fromDate', (fromDate));
    req.putParam('toDate', (toDate));
    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids));

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    let arr;
    const date = (toDate);
    if (result.first.size > 0) {

        arr = result.first.toArray().filter((d) => {
            let res = true;
            if (d.client_id) {
                const client = JSDB().getSupplier(d.client_id).first
                const master =  client
                if (citys && citys.length > 0) {
                    const c = master.getCity(date);
                    res = res && citys.some((city) => c.includes(city));

                }
                if (agents && agents.length > 0) {
                    const agent = master.getAgent(date);
                    res = res && agents.some((agen) => agent == (agen));
                }
                if (drivers && drivers.length > 0) {
                    const agent = master.getDriverFinder(date)?.user_name;
                    res = agent && res && drivers.some((agen) => agent == (agen));

                }
            }

            return res;

        });
    } else {
        return [];
    }
    return debtQDeserializeSup(arr, date);
};

export const getCostMapping: (arr, date, totalCost: number, costData: CostData[]) => any = (arr, date, totalCost, costData) => {
    const d = {};
    d[-1] = 0;

    arr.forEach((q) => {
        const driver = JSDB().getClient(q.byEnt).first.getDriverFinder(date)?.id;
        const x = roundDecimals(q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0), 2);
        if (d[driver])
            d[driver] += x;
        else d[driver] = x;
        d[-1] += roundDecimals(q.valueSum + ((q.valueSumSecJoin) ? q.valueSumSecJoin : 0), 2);

    });

    const results = {};
    if (d[-1] == 0)
        return results;
    results[-1] = totalCost / d[-1];
    costData.forEach((cd) => {

        if (d[cd.id])
            results[cd.id] = (cd.value) / d[cd.id];
        else results[cd.id] = 0;

    });
    return results;
};
export const getSupAmountsQuery: (fromDate: Date, toDate: Date, citys?: string[], creators?: string[]) => Promise<NewAmountDataHolder[]> = async (fromDate, toDate, citys, creators, c2p) => {
    const req = new ApiCall('QUERY_DATA_AMOUNTS');
    req.putParam('fromDate', dateObjectToString(fromDate));
    req.putParam('toDate', dateObjectToString(toDate));
    req.putParam('byProduct', NKTypeConvertor().toKotlinList([-1]));
    req.putParam('byEnt', NKTypeConvertor().toKotlinList([-1]));
    req.putParam('doc_type', 6);
    if (creators && creators.length > 0)
        req.putParam('byAgent', NKTypeConvertor().toKotlinList(creators));
    req.putParam('byMoney', true);

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    let arr;

    const date = dateObjectToString(toDate);
    if (result.first.size > 0) {
        arr = result.first.toArray().filter((d) => {
            let res = true;
            if (citys && citys.length > 0 && d.byEnt) {
                const c = JSDB().getSupplier(d.byEnt).first.getCity(date);
                res = res && citys.some((city) => c.includes(city));

            }
            return res;

        });
    } else {
        return [];
    }


    return newSupAmountQDeserialize(arr, date);
}
export const getAmountsQuery: (fromDate: Date, toDate: Date, citys?: string[], agents?: string[], creators?: string[], drivers?: string[], category?: string[], bnames?: string[], c2p?: boolean, totalCost?: number, totalCostSplitAgent?: number, costData?: CostData[], additionalCost?: any, drivers_first?: boolean, order?: boolean, paid?: boolean, filter_date?: Date, groupByMonth?: boolean, mecher?: any) => Promise<NewAmountDataHolder[]> = async (fromDate, toDate, citys, agents, creators, drivers, category, bnames, c2p, totalCost, totalCostSplitAgent, costData, additionalCost, drivers_first, order, paid, filter_date, groupByMonth, mecher) => {

    const req = new ApiCall('QUERY_DATA_AMOUNTS');
    req.putParam('fromDate', dateObjectToString(fromDate));
    req.putParam('toDate', dateObjectToString(toDate));
    req.putParam('byProduct', NKTypeConvertor().toKotlinList([-1]));
    req.putParam('byEnt', NKTypeConvertor().toKotlinList([-1]));
    if (creators && creators.length > 0)
        req.putParam('byAgent', NKTypeConvertor().toKotlinList(creators));
    req.putParam('byMoney', true);
    req.putParam('byCost', costData != undefined);
    if (c2p || (drivers && drivers.length > 0) || drivers_first)
        req.putParam('withDaily', true);
    if (order)
        req.putParam('doc_type', 10);
    if (paid != undefined)
        req.putParam('paid', paid);
    if (filter_date)
        req.putParam('filter_date', dateObjectToString(filter_date));
    if (groupByMonth) {
        req.putParam('byMonth', -1);
        req.putParam('byYear', -1);
    }

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    let arr;

    const date = dateObjectToString(toDate);
    let costMapping: any = undefined;
    if (result.first.size > 0) {
        if (totalCost != undefined && costData) {
            arr = totalCostSplitAgent ? result.first.toArray().filter((d) => {
                let res = true;
                if (agents && agents.length > 0 && d.byEnt) {
                    const agent = JSDB().getClient(d.byEnt).first.getAgent(date);
                    res = res && agents.some((agen) => agent == (agen));
                }
                return res;

            }) : result.first.toArray();
            costMapping = getCostMapping(arr, date, totalCost, costData);
        }
        arr = result.first.toArray().filter((d) => {
            let res = true;

            if (citys && citys.length > 0 && d.byEnt) {
                const c = JSDB().getClient(d.byEnt).first.getCity(date);
                res = res && citys.some((city) => c.includes(city));

            }
            if (agents && agents.length > 0 && d.byEnt) {
                const agent = JSDB().getClient(d.byEnt).first.getAgent(date);
                res = res && agents.some((agen) => agent == (agen));
            }
            if (drivers && drivers.length > 0 && d.byEnt) {
                const agent = JSDB().getClient(d.byEnt).first.getDriverFinder(date)?.user_name;
                res = agent && res && drivers.some((agen) => agent == (agen));

            }
            if (category && category.length > 0 && d.byEnt) {
                const c = JSDB().getClient(d.byEnt).first.getCategory(date);
                res = res && category.some((city) => c.includes(city));

            }

            if (bnames && bnames.length > 0 && d.byEnt) {
                const c = JSDB().getClient(d.byEnt).first.getBusinessName(date);
                res = res && bnames.some((city) => c.includes(city));

            }
            return res;

        });

    } else {
        return [];
    }
    let orderMap = undefined
    if (mecher) {
        orderMap = {};
        const req = new ApiCall('QUERY_DATA_BASE_AMOUNTS');
        req.putParam('fromDate', dateObjectToString(fromDate));
        req.putParam('toDate', dateObjectToString(toDate));
        req.putParam('byProduct', NKTypeConvertor().toKotlinList([-1]));
        req.putParam('byEnt', NKTypeConvertor().toKotlinList([-1]));

        if (creators && creators.length > 0)
            req.putParam('byAgent', NKTypeConvertor().toKotlinList(creators));
        req.putParam('byMoney', true);
        const result2 = await req.execute();
        if (result2 == null || result2.second.name !== 'SUCCESS')
            return new Promise((resolve, reject) => {
                reject(Error);
            });
        toArrayOrEmpty(result2.first).forEach((d) => {
            orderMap[`${d.byEnt}-${d.byProduct}`] = d;
        })
    }
    if (paid == false)
        return amountQDeserializeC2P(arr, date, undefined)
    return newAmountQDeserialize(arr, date, costMapping, additionalCost, orderMap, mecher);
};

export default getAmountsQuery;

